import { keyframes } from '@emotion/css';
import styled from '@emotion/styled';
import { getValidLocale, useTranslate } from 'callabo-locale/src/hooks/useTranslate';
import clsx from 'clsx';
import { PageProps } from 'libs/callabo-state/types/PageProps';
import Image from 'next/image';
import { useEffect, useRef, useState } from 'react';

type videoType = {
    webm: string;
    mp4: string;
};
type Meeting = 'sales' | 'internal';
type Script = {
    summary: string[];
    takeaway: string[];
    actionItemTs: Array<{
        text: string;
        startAt: string;
        reference: string;
    }>;
};

const videoSrc: videoType | any = {
    webm: 'https://returnzero-public-image.s3.ap-northeast-2.amazonaws.com/callabo/callabo-homepage-main/homepage-main-demo-01-en.webm',
    mp4: 'https://returnzero-public-image.s3.ap-northeast-2.amazonaws.com/callabo/callabo-homepage-main/homepage-main-demo-01-en.mp4',
};

export const Demo = ({ locale }: PageProps): JSX.Element => {
    const { t } = useTranslate({ locale });
    const videoRef = useRef<HTMLVideoElement>(null);
    const topRef = useRef<HTMLDivElement>(null);
    const [meetingType, setMeetingType] = useState<Meeting>('sales');
    const insightScript: Record<Meeting, Script> = {
        sales: {
            summary: [
                t.home.SCRIPT_SALES_SUMMARY_1,
                t.home.SCRIPT_SALES_SUMMARY_2,
                t.home.SCRIPT_SALES_SUMMARY_3,
                t.home.SCRIPT_SALES_SUMMARY_4,
                t.home.SCRIPT_SALES_SUMMARY_5,
            ],

            takeaway: [
                t.home.SCRIPT_SALES_TAKEAWAY_1,
                t.home.SCRIPT_SALES_TAKEAWAY_2,
                t.home.SCRIPT_SALES_TAKEAWAY_3,
                t.home.SCRIPT_SALES_TAKEAWAY_4,
                t.home.SCRIPT_SALES_TAKEAWAY_5,
                t.home.SCRIPT_SALES_TAKEAWAY_6,
                t.home.SCRIPT_SALES_TAKEAWAY_7,
                t.home.SCRIPT_SALES_TAKEAWAY_8,
            ],
            actionItemTs: [
                {
                    text: t.home.SCRIPT_SALES_ACTIONITEM_1_TEXT,
                    startAt: '11:04',
                    reference: `“${t.home.SCRIPT_SALES_ACTIONITEM_1_TEXT}“`,
                },
            ],
        },
        internal: {
            summary: [
                t.home.SCRIPT_INTERNAL_SUMMARY_1,
                t.home.SCRIPT_INTERNAL_SUMMARY_2,
                t.home.SCRIPT_INTERNAL_SUMMARY_3,
            ],

            takeaway: [
                t.home.SCRIPT_INTERNAL_TAKEAWAY_1,
                t.home.SCRIPT_INTERNAL_TAKEAWAY_2,
                t.home.SCRIPT_INTERNAL_TAKEAWAY_3,
                t.home.SCRIPT_INTERNAL_TAKEAWAY_4,
            ],
            actionItemTs: [
                {
                    text: t.home.SCRIPT_INTERNAL_ACTIONITEM_1_TEXT,
                    startAt: '11:04',
                    reference: `“${t.home.SCRIPT_INTERNAL_ACTIONITEM_1_TEXT}“`,
                },
                {
                    text: t.home.SCRIPT_INTERNAL_ACTIONITEM_2_TEXT,
                    startAt: '45:01',
                    reference: `“${t.home.SCRIPT_INTERNAL_ACTIONITEM_2_TEXT}“`,
                },
            ],
        },
    };

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.disableRemotePlayback = true;
        }
    }, []);

    const onChangeMeetingType = (type: Meeting) => {
        setMeetingType(type);
        topRef.current?.scrollTo({
            top: 0,
        });
    };

    return (
        <Bottom className="relative w-[400px] max-w-[calc(100%-20px)] xl:w-full xl:flex gap-10 xl:max-h-[795px] py-3.5 xl:p-[50px] text-left mt-10 xl:mt-20 rounded-[28px] xl:rounded-[56px] bg-blue-pale xl:bg-white border border-solid border-[#E6ECFF] xl:border-none xl:shadow-[0px_4px_32px_0px_rgba(0,0,0,0.12)]">
            <div className="basis-[60%] select-none pointer-events-none px-3.5 xl:px-0">
                <video
                    key={`mainVideo-${getValidLocale(locale as string)}`}
                    preload="metadata"
                    loop
                    muted
                    autoPlay
                    playsInline
                    disableRemotePlayback
                    ref={videoRef}
                    className="w-full max-w-full rounded-[18px] xl:rounded-[36px]"
                >
                    <source src={videoSrc.webm} type="video/webm" />
                    <source src={videoSrc.mp4} type="video/mp4" />
                </video>
                <Image
                    src={require('../assets/image/player.png')}
                    className="hidden xl:block mt-5"
                    alt={'player'}
                />
                <Image
                    src={require('../assets/image/audio_bar.png')}
                    className="hidden xl:block mt-2.5"
                    alt={'audio bar'}
                />
                <Image
                    src={require('../assets/image/speakers.png')}
                    className="hidden xl:block mt-5"
                    alt={'speakers'}
                />
            </div>
            <div className="basis-full xl:basis-[40%] max-h-full overflow-hidden pt-4 xl:pt-0">
                <div
                    ref={topRef}
                    className="flex flex-col gap-5 overflow-auto max-h-[332px] xl:max-h-full custom-scrollbar pb-4 xl:pb-0 px-3.5 xl:px-0"
                >
                    {/* n 문단 요약 */}
                    <div className="rounded-lg xl:p-5 bg-blue-pale">
                        <h3 className="flex items-center gap-2 select-none text-sm xl:text-base text-black font-semibold">
                            <Image
                                src={require('../assets/icon/summary.svg')}
                                width={20}
                                height={20}
                                className="flex-shrink-0"
                                alt={'summary'}
                            />
                            <span>
                                {t.record.INSIGHT_SUMMARY_TITLE_DYNAMIC.replace(
                                    '{{count}}',
                                    insightScript[meetingType].summary.length.toString()
                                )}
                            </span>
                        </h3>
                        <ul className="flex flex-col gap-3.5 mt-3.5 xl:mt-5">
                            {insightScript[meetingType].summary.map((item, idx) => {
                                return (
                                    <li
                                        key={`summary-${idx}`}
                                        className="flex gap-1 xl:gap-2.5 text-black text-sm xl:text-base"
                                    >
                                        <b className="min-w-[20px] font-semibold text-center">
                                            {idx + 1}.
                                        </b>
                                        <p
                                            dangerouslySetInnerHTML={{ __html: item }}
                                            className="*:bg-[#fffc8c80]"
                                        />
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    {/* 주요 논의 사항 */}
                    <div className="rounded-lg xl:p-5 bg-blue-pale">
                        <h3 className="flex items-center gap-2 select-none text-sm xl:text-base text-black font-semibold">
                            <Image
                                src={require('../assets/icon/takeaway.svg')}
                                width={20}
                                height={20}
                                className="flex-shrink-0"
                                alt={'takeaway'}
                            />
                            <span>{t.record.INSIGHT_TAKEAWAY_TITLE}</span>
                        </h3>
                        <ul className="flex flex-col gap-3.5 mt-3.5 xl:mt-5 text-black">
                            {insightScript[meetingType].takeaway.map((item, idx) => {
                                return (
                                    <li
                                        key={`takeaway-${idx}`}
                                        className="flex gap-1 xl:gap-2.5 text-sm xl:text-base before:content-[''] before:min-w-[4px] before:w-1 before:h-1 before:bg-black before:m-2"
                                    >
                                        {item}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    {/* 액션 아이템 */}
                    <div className="rounded-lg xl:p-5 bg-blue-pale">
                        <h3 className="flex items-center gap-2 select-none text-sm xl:text-base text-black font-semibold">
                            <Image
                                src={require('../assets/icon/actionItemTs.svg')}
                                width={20}
                                height={20}
                                className="flex-shrink-0"
                                alt={'actionItemTs'}
                            />
                            <span>{t.record.INSIGHT_ACTION_ITEM_TS_TITLE}</span>
                        </h3>
                        <ul className="flex flex-col pl-1.5 gap-3.5 xl:p-0 xl:gap-0 mt-3.5 xl:mt-5">
                            {insightScript[meetingType].actionItemTs.map((item, idx) => {
                                return (
                                    <li
                                        key={`actionItem-${idx}`}
                                        className="h-full xl:py-4 xl:px-2"
                                    >
                                        <p className="text-sm leading-[150%] pl-5 border-l-2 border-l-black border-solid">
                                            {item.text}
                                        </p>
                                        <div className="flex items-start gap-2 mt-3">
                                            <button className="h-6 flex justify-center items-center shrink-0 border text-primary bg-transparent text-sm px-2.5 py-1 rounded-xl border-solid border-gray-ivory cursor-default">
                                                {item.startAt}
                                            </button>
                                            <p className="text-gray text-xs leading-[140%] pt-[5px]">
                                                {item.reference}
                                            </p>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </div>
            {/* mobile 버전 하단 플레이어 영역 */}
            <div className="xl:hidden pt-3 pb-1.5 px-3.5 border-t-[1px] border-solid border-gray-fog">
                <Image src={require('../assets/image/mobile_audio_bar.png')} alt={'audio bar'} />
                <Image
                    src={require('../assets/image/mobile_player.png')}
                    className="mt-6"
                    alt={'player'}
                />
            </div>
            <ToggleScriptTooltip
                selectedType={meetingType}
                onClick={onChangeMeetingType}
                locale={locale}
            />
        </Bottom>
    );
};

const ToggleScriptTooltip = ({
    selectedType,
    onClick,
    locale,
}: {
    selectedType: Meeting;
    onClick: (type: Meeting) => void;
} & PageProps) => {
    const { t } = useTranslate({ locale });
    return (
        <div className="absolute bottom-[120px] h-fit right-4 xl:-top-[60px] xl:left-[60%] xl:w-fit select-none z-10">
            <div className="bg-primary py-4 rounded-lg translate-y-1 shadow-[0px_4px_12px_rgba(0,0,0,0.25)] flex flex-col">
                <p className="text-xs xl:text-sm font-semibold text-white px-4">
                    {t.home.SCRIPT_TOOLTIP_DESC}
                </p>
                <div className="flex items-center gap-2 mt-2 xl:mt-3 px-4">
                    <button
                        onClick={() => onClick('sales')}
                        className={clsx(
                            'w-[50%] max-h-9 whitespace-nowrap rounded text-xs xl:text-sm p-2.5 border border-solid flex justify-center items-center box-border',
                            selectedType === 'sales'
                                ? 'text-primary bg-white'
                                : 'text-white font-semibold border-[rgba(255,255,255,0.10)]'
                        )}
                    >
                        {t.home.SCRIPT_TOOLTIP_SALES}
                    </button>
                    <button
                        onClick={() => onClick('internal')}
                        className={clsx(
                            'w-[50%] max-h-9 whitespace-nowrap rounded text-xs xl:text-sm p-2.5 border border-solid flex justify-center items-center box-border',
                            selectedType === 'internal'
                                ? 'text-primary bg-white border-white'
                                : 'text-white font-semibold border-[rgba(255,255,255,0.10)]'
                        )}
                    >
                        {t.home.SCRIPT_TOOLTIP_INTERNAL}
                    </button>
                </div>
            </div>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="10"
                viewBox="0 0 12 10"
                fill="none"
                className="absolute top-full left-[50%] xl:top-[102px] xl:left-5"
            >
                <path
                    d="M5.13397 9.5C5.51887 10.1667 6.48113 10.1667 6.86603 9.5L11.1962 2C11.5811 1.33333 11.0999 0.5 10.3301 0.5H1.66987C0.900073 0.5 0.418948 1.33333 0.803848 2L5.13397 9.5Z"
                    fill="#2E67FE"
                />
            </svg>
        </div>
    );
};

const riseFast = keyframes`
    0% { opacity: 0; transform: translateY(16px); }
    50% { opacity: 0; transform: translateY(16px); }
    100% { opacity: 1; transform: translateY(0); }
`;

const Bottom = styled.section`
    animation: ${riseFast} 1.6s ease-in-out;
`;
