export function downloadCanvas(canvas: HTMLCanvasElement): void {
    downloadImage(canvas.toDataURL(), 'fileName.png');
}

export function downloadImage(src: string, fileName: string): void {
    downloadFile(src, fileName);
}

export function downloadFile(src: string, fileName: string): void {
    // file명에 콜론이 안돼서 똑같이 생긴 다른 문자로 대체.
    fileName.replace(/:/g, '꞉');
    const link = document.createElement('a');
    link.href = src;
    link.download = fileName;
    link.click();
}

export function downloadTexts(texts: Array<string>, fileName: string): void {
    const text = texts.join('\n').replace(/#/g, '');
    downloadFile(encodeURI('data:text/plain;charset=utf-8,\uFEFF' + text), fileName);
}

export function downloadCSV(
    header: Array<string>,
    data: Array<Array<string>>,
    fileName: string
): void {
    const csvContent =
        'data:text/csv;charset=utf-8,\uFEFF' +
        header.join(',') +
        '\n' +
        data
            .map((e) =>
                e
                    .map(
                        (val) => '"' + val + '"' // 콤마가 들어갈 수 있는 데이터는 더블쿼터로 감싸줌.
                    )
                    .join(',')
            )
            .join('\n');
    downloadFile(encodeURI(csvContent), fileName);
}

export async function fetchAndDownloadFile(src: string, fileName: string): Promise<void> {
    if (!src) return;
    const response = await fetch(src);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    downloadFile(url, fileName);
    window.URL.revokeObjectURL(url);
}
