import { HomepageMedia } from '../design/HomapageMedia';
import { HomepageStyle } from '../design/HomepageStyle';
import { PathsForExternal } from '@/modules/Paths';
import styled from '@emotion/styled';
import { Locales, useTranslate } from 'callabo-locale/src/hooks/useTranslate';
import Image from 'next/image';
import Link from 'next/link';

export const Integration = ({ locale }: { locale: Locales }): JSX.Element => {
    const { t } = useTranslate({ locale });
    return (
        <HomepageStyle.Card style={{ backgroundColor: '#2B2F39' }} order={2} height={989}>
            <HomepageStyle.CardTitle
                dangerouslySetInnerHTML={{ __html: t.home.INTEGRATION_TITLE }}
                style={{ color: '#fff' }}
            />
            <Tools>
                <Item>
                    <Logo>
                        <Image
                            src={require('../assets/integration/google_meet.svg')}
                            style={{
                                width: '100%',
                                height: '100%',
                            }}
                            alt={'google meet'}
                        />
                    </Logo>
                    <p>Google Meet</p>
                </Item>
                <Item>
                    <Logo>
                        <Image
                            src={require('../assets/integration/zoom.svg')}
                            style={{
                                width: '100%',
                                height: '100%',
                            }}
                            alt={'zoom'}
                        />
                    </Logo>
                    <p>Zoom</p>
                </Item>
                <Item>
                    <Logo>
                        <Image
                            src={require('../assets/integration/teams.svg')}
                            style={{
                                width: '100%',
                                height: '100%',
                            }}
                            alt={'logo'}
                        />
                    </Logo>
                    <p>MS Teams</p>
                </Item>
                <Item>
                    <Logo>
                        <Image
                            src={require('../assets/integration/recorder.svg')}
                            style={{
                                width: '100%',
                                height: '100%',
                            }}
                            alt={'recorder app'}
                        />
                    </Logo>
                    <p dangerouslySetInnerHTML={{ __html: t.pricing.PLAN_PLUS_TOOLS_RECORDER }} />
                </Item>
                <Item>
                    <Logo>
                        <Image
                            src={require('../assets/integration/google_calendar.svg')}
                            style={{
                                width: '100%',
                                height: '100%',
                            }}
                            alt={'google calendar'}
                        />
                    </Logo>
                    <p>Google Calendar</p>
                </Item>
                <Item>
                    <Logo>
                        <Image
                            src={require('../assets/integration/outlook_calendar.svg')}
                            style={{
                                width: '100%',
                                height: '100%',
                            }}
                            alt={'outlook calendar'}
                        />
                    </Logo>
                    <p>Outlook Calendar</p>
                </Item>
                <Item>
                    <Logo>
                        <Image
                            src={require('../assets/integration/slack.svg')}
                            style={{
                                width: '100%',
                                height: '100%',
                            }}
                            alt={'slack'}
                        />
                    </Logo>
                    <p>Slack</p>
                </Item>
                <Item>
                    <Logo>
                        <Image
                            src={require('../assets/integration/salesforce.svg')}
                            style={{
                                width: '100%',
                                height: '100%',
                            }}
                            alt={'logo'}
                        />
                    </Logo>
                    <p>Salesforce</p>
                </Item>
                <Item>
                    <Logo>
                        <Image
                            src={require('../assets/integration/zapier.svg')}
                            style={{
                                width: '100%',
                                height: '100%',
                            }}
                            alt={'zapier'}
                        />
                    </Logo>
                    <p>Zapier</p>
                </Item>
            </Tools>
            <Link href={PathsForExternal.Email} passHref>
                <InquiryButton>
                    <Icon>
                        <Image
                            src={require('../assets/icon/integration_email.svg')}
                            style={{
                                width: '100%',
                                height: '100%',
                            }}
                            alt={'onboarding'}
                        />
                    </Icon>
                    <span dangerouslySetInnerHTML={{ __html: t.home.INTEGRATION_INQUIRY }} />
                </InquiryButton>
            </Link>
        </HomepageStyle.Card>
    );
};

const Tools = styled.ul`
    width: 100%;
    list-style: none;
    display: grid;
    gap: 52px;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    padding: 100px 80px;
    ${HomepageMedia.forMobile(`
        gap: 27px 12px;
        padding: 40px 16px 40px 16px;
    `)}
`;

const Item = styled.li`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    user-select: none;
    -webkit-user-select: none;
    ${HomepageMedia.forMobile(`
        max-width: 80px;
        font-size: 0.875rem;
        gap: 14px;
    `)}

    p {
        color: #ccc;

        font-size: 1.25rem;
        font-weight: 400;
        text-align: center;
        ${HomepageMedia.forMobile(`
            font-size: 0.875rem;
        `)}
    }
`;

const InquiryButton = styled.button`
    display: flex;
    padding: 32px 40px;
    justify-content: center;
    align-items: center;
    gap: 12px;

    border-radius: 14px;
    background: #2e67fe;
    box-shadow: 0px 14px 32px 0px rgba(0, 31, 113, 0.15);

    color: #fff;
    text-align: center;

    font-size: 1.25rem;
    font-weight: 600;
    ${HomepageMedia.forMobile(`
        border-radius: 4px;
        font-size: 0.875rem;
        gap: 4px;
        padding: 16px 32px;
    `)}
`;

const Logo = styled.div`
    width: 80px;
    height: 80px;
    ${HomepageMedia.forMobile(`
        width: 40px;
        height: 40px;
    `)}
`;

const Icon = styled.div`
    width: 44px;
    height: 32px;
    ${HomepageMedia.forMobile(`
        width: 26px;
        height: 18px;
    `)}
`;
