import { AuthContext } from '@/contexts/AuthContext';
import { PathName } from '@/modules/PathName';
import { Paths } from '@/modules/Paths';
import Redirect from '@/modules/Redirect';
import { MetaPixel } from '@/seo/MetaPixel';
import { Analysis } from '@/ui/Home/Body/Analysis';
import { AppSupport } from '@/ui/Home/Body/AppSupport';
import { EmphasisPayment } from '@/ui/Home/Body/EmphasisPayment';
import { Feature } from '@/ui/Home/Body/Feature';
import { Integration } from '@/ui/Home/Body/Integration';
import { Intro } from '@/ui/Home/Body/Intro';
import { Partner } from '@/ui/Home/Body/Partner';
import { UsageDemo } from '@/ui/Home/Body/UsageDemo';
import { UseCase } from '@/ui/Home/Body/UseCase';
import { Vacation } from '@/ui/Home/Body/Vacation';
import { Footer } from '@/ui/Home/Footer';
import { GNB } from '@/ui/Home/GNB';
import { Header } from '@/ui/Home/Header';
import { HomepageStyle } from '@/ui/Home/design/HomepageStyle';
import { getValidLocale } from 'callabo-locale/src/hooks/useTranslate';
import { Accessibility, CallaboPage } from 'libs/callabo-state/types/CallaboPage';
import { PageProps } from 'libs/callabo-state/types/PageProps';
import { Phase } from 'libs/callabo-state/types/Phase';
import { GetServerSideProps } from 'next';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { useContext, useState } from 'react';

const { publicRuntimeConfig } = getConfig();

export const Index: CallaboPage = ({ locale }: PageProps) => {
    const { authState } = useContext(AuthContext);
    const [isButtonInViewport, setIsButtonInViewport] = useState(true);
    const router = useRouter();

    switch (authState.type) {
        case 'signedIn': {
            return <Redirect url={Paths.WorkspaceIndex} />;
        }
        case 'loading':
        case 'signedOut': {
            if (router.query.locale !== locale) {
                return <Redirect url={new PathName(Paths.Index).setLocale('ko').toString()} />;
            }

            return (
                <>
                    {publicRuntimeConfig.PHASE === Phase.production && <MetaPixel />}
                    <HomepageStyle.Container>
                        <GNB locale={locale} highlightButton={!isButtonInViewport} />
                        <Header locale={locale} onScroll={setIsButtonInViewport} />
                        <HomepageStyle.CardContainer>
                            <Partner locale={locale} />
                            <Intro locale={locale} />
                            <Integration locale={locale} />
                            <UseCase locale={locale} />
                            <Analysis locale={locale} />
                            <Vacation locale={locale} />
                        </HomepageStyle.CardContainer>
                        <Feature locale={locale} />
                        <EmphasisPayment locale={locale} />
                        <UsageDemo locale={locale} />
                    </HomepageStyle.Container>
                    <AppSupport locale={locale} />
                    <Footer locale={locale} />
                </>
            );
        }
    }
};

export const getServerSideProps: GetServerSideProps = async (context) => {
    const locale = getValidLocale(context.params?.locale as string);
    return {
        props: {
            locale,
        },
    };
};

Index.accessibility = Accessibility.EVERYONE;
Index.isHomepage = true;

export default Index;
