import { HomepageMedia } from '../design/HomapageMedia';
import { HomepageStyle } from '../design/HomepageStyle';
import styled from '@emotion/styled';
import { Locales, getValidLocale, useTranslate } from 'callabo-locale/src/hooks/useTranslate';
import Image from 'next/image';

export const Vacation = ({ locale }: { locale: Locales }): JSX.Element => {
    const { t } = useTranslate({ locale });
    return (
        <HomepageStyle.Card order={6} height={721}>
            <Container>
                <HomepageStyle.CardTitle
                    dangerouslySetInnerHTML={{ __html: t.home.HOW_TO_VACATION_TITLE }}
                />
                <HomepageStyle.CardDescription
                    dangerouslySetInnerHTML={{ __html: t.home.HOW_TO_VACATION_DESC }}
                />
                <Content>
                    <Image
                        src={require(`../assets/image/${getValidLocale(locale)}/vacation.png`)}
                        width={372}
                        height={372}
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                        alt="미팅참여"
                    />
                </Content>
            </Container>
        </HomepageStyle.Card>
    );
};

const Container = styled.div`
    width: 100%;
    padding-left: 64px;
    padding-right: 64px;
    ${HomepageMedia.forMobile(`
        padding-left: 20px;
        padding-right: 20px;
    `)}
`;

const Content = styled.div`
    max-width: 372px;
    margin: 40px auto 0 auto;
`;
