import { PDF_SCRIPT } from '../assets/script/CaseStudyPDF';
import { CaseStudyCard } from '../components/CaseStudyCard';
import { PageProps } from 'libs/callabo-state/types/PageProps';
import { useEffect, useRef, useState } from 'react';

const cols = Math.floor(PDF_SCRIPT.length / 2) + 1;

/** @description 한국어일 때만 노출되는 컴포넌트 */
export const CaseStudyList = ({ locale }: PageProps): JSX.Element => {
    const [currentDot, setCurrentDot] = useState(0);
    const containerRef = useRef<HTMLDivElement>(null);

    const handleScroll = () => {
        const container = containerRef.current;
        if (!container) return;
        const scrollLeft = container.scrollLeft;
        const maxScrollLeft = container.scrollWidth - container.clientWidth;
        const scrollFraction = scrollLeft / maxScrollLeft;
        setCurrentDot(Math.round(scrollFraction * (cols - 1)));
    };

    useEffect(() => {
        const container = containerRef.current;
        if (!container) return;
        container.addEventListener('scroll', handleScroll);
        return () => {
            container.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <div className="w-[400px] max-w-full xl:w-full mt-3.5 mb-2 xl:mt-20 xl:mb-14">
            <div
                ref={containerRef}
                className="grid grid-rows-[repeat(2,250px)] grid-cols-[repeat(3,290px)] xl:grid-rows-[repeat(2,300px)] xl:grid-cols-[repeat(3,380px)] xl:justify-center gap-3.5 xl:gap-10 overflow-x-auto no-scrollbar px-3 xl:px-0"
            >
                {PDF_SCRIPT.map((_, idx) => {
                    return (
                        <CaseStudyCard key={`case-study-${idx}`} targetCase={idx} locale={locale} />
                    );
                })}
            </div>
            {/* 모바일 스크롤 표시 */}
            <div className="xl:hidden flex justify-center gap-1 mt-3">
                {[...Array(cols)].map((_, idx) => (
                    <div
                        key={`case-${idx}`}
                        className={`w-1.5 h-1.5 rounded-full transition ease-in-out duration-100 ${
                            currentDot === idx ? 'bg-navy' : 'bg-blue-pale'
                        }`}
                    />
                ))}
            </div>
        </div>
    );
};
