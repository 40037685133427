import { HomepageMedia } from '../design/HomapageMedia';
import { HomepageStyle } from '../design/HomepageStyle';
import { PathsForExternal } from '@/modules/Paths';
import styled from '@emotion/styled';
import { Locales, useTranslate } from 'callabo-locale/src/hooks/useTranslate';
import Image from 'next/image';
import Link from 'next/link';

export const Intro = ({ locale }: { locale: Locales }): JSX.Element => {
    const { t } = useTranslate({ locale });
    return (
        <HomepageStyle.Card order={4} height={634}>
            <HomepageStyle.CardTitle dangerouslySetInnerHTML={{ __html: t.home.INTRO_DESC }} />
            <Title dangerouslySetInnerHTML={{ __html: t.home.INTRO_TITLE }} />
            <div>
                <Link href={PathsForExternal.WhatTime} target="_blank">
                    <OnboardingButton>
                        <Icon>
                            <Image
                                src={require('../assets/icon/inquiry_onboarding.svg')}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                }}
                                alt={'onboarding'}
                            />
                        </Icon>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: t.home.INTRO_ONBOARDING_MEETING,
                            }}
                        />
                    </OnboardingButton>
                </Link>
                <Divider>
                    <div />
                    <span dangerouslySetInnerHTML={{ __html: t.home.INTRO_OR }} />
                    <div />
                </Divider>
            </div>
            <ButtonGroup>
                <Link href={PathsForExternal.Email} passHref>
                    <Button>
                        <Image
                            src={require('../assets/icon/inquiry_email.svg')}
                            width={20}
                            height={20}
                            layout="fixed"
                            className="flex-shrink-0"
                            alt={'이메일'}
                        />
                        <span dangerouslySetInnerHTML={{ __html: t.home.INTRO_EMAIL }} />
                    </Button>
                </Link>
            </ButtonGroup>
        </HomepageStyle.Card>
    );
};

const Title = styled.h4`
    color: #222;
    text-align: center;

    font-size: 3.25rem;
    font-weight: 600;
    margin-top: 40px;
    ${HomepageMedia.forMobile(`
        font-size: 1.5rem; 
        margin-top: 16px;
    `)}
`;

const OnboardingButton = styled.button`
    display: flex;
    padding: 32px 40px;
    justify-content: center;
    align-items: center;
    gap: 12px;

    border-radius: 14px;
    background: #001f71;
    box-shadow: 0px 14px 32px 0px rgba(0, 31, 113, 0.15);
    margin-top: 62px;

    color: #fff;
    text-align: center;

    font-size: 1.25rem;
    font-weight: 600;
    transition: all 0.2s ease-in-out;
    ${HomepageMedia.forMobile(`
        font-size: 0.875rem; 
        margin-top: 32px;
        padding: 16px 32px;
        border-radius: 4px;
    `)}
    &:hover {
        background: #2e67fe;
        color: #fff;
        box-shadow: 0px 14px 32px 0px rgba(0, 31, 113, 0.3);
    }
`;

const Divider = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    div {
        width: 50%;
        height: 1px;
        background-color: #cccdcc;
    }
    span {
        margin: 46px 0 30px 0;

        font-weight: 400;
        font-size: 1.25rem;
        color: #999;
        padding: 0 36px;
        white-space: nowrap;
        ${HomepageMedia.forMobile(`
            font-size: 0.875rem; 
            margin: 24px 16px;
            padding: 0 20px;
        `)}
    }
`;

const ButtonGroup = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    gap: 14px;
    ${HomepageMedia.forMobile(`
        gap: 8px;
    `)}
`;

const Button = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 4px;
    border: 1px solid #e6ecff;
    color: #001f71;

    font-size: 1.25rem;
    font-weight: 600;
    white-space: nowrap;
    padding: 27px 32px;
    box-sizing: border-box;
    ${HomepageMedia.forMobile(`
        width: 212px;
        font-size: 0.875rem; 
        padding: 14px 0;
        border-radius: 4px;
        gap: 4px;
    `)}
`;

const Icon = styled.div`
    width: 32px;
    height: 32px;
    ${HomepageMedia.forMobile(`
        width: 24px;
        height: 24px;
    `)}
`;
