import { CaseStudyList } from './Body/CaseStudyList';
import { Demo } from './Body/Demo';
import { HomepageMedia } from './design/HomapageMedia';
import { HomepageStyle } from './design/HomepageStyle';
import { useDebounce } from '@/hooks/useDebounce';
import { Paths, PathsForExternal } from '@/modules/Paths';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { getValidLocale, useTranslate } from 'callabo-locale/src/hooks/useTranslate';
import { PageProps } from 'libs/callabo-state/types/PageProps';
import Image from 'next/image';
import Link from 'next/link';
import { Dispatch, SetStateAction, useEffect, useRef } from 'react';

interface Props {
    onScroll: Dispatch<SetStateAction<boolean>>;
}

export const Header = ({ locale, onScroll }: Props & PageProps): JSX.Element => {
    const { t } = useTranslate({ locale });
    const startButtonRef = useRef<HTMLButtonElement>(null);
    const { debounce } = useDebounce();

    useEffect(() => {
        const startButton = startButtonRef.current;
        if (!startButton) return;
        const handleIntersectionButton = (entries) => {
            debounce(() => {
                entries.forEach((entry) => {
                    onScroll(entry.intersectionRatio === 1);
                });
            });
        };
        const observerForButton = new IntersectionObserver(handleIntersectionButton, {
            root: null,
            threshold: 1,
        });
        observerForButton.observe(startButton);
        return () => {
            observerForButton.unobserve(startButton);
        };
    }, []);

    return (
        <Container>
            <Contents>
                <div>
                    <Catchphrase dangerouslySetInnerHTML={{ __html: t.home.HEADER_CATCHPHRASE }} />
                    <Tags>
                        {t.home.HEADER_TAG.split(' ').map((tag, i) => {
                            return <span key={`tag-${i}`}>{tag}</span>;
                        })}
                    </Tags>
                </div>
                <div>
                    <Image
                        src={require(`./assets/image/${getValidLocale(
                            locale as string
                        )}/header_image.png`)}
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                        alt={'콜라보 제품의 특징을 설명하는 이미지'}
                    />
                </div>
            </Contents>
            <ButtonGroup>
                <Link href={Paths.Login} passHref>
                    <StartButton ref={startButtonRef}>
                        <ButtonInner>
                            <Icon>
                                <Image
                                    src={require('./assets/icon/header_logo_white.svg')}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                    }}
                                    alt={'logo'}
                                />
                            </Icon>
                            <span>{t.common.START_FOR_FREE_BUTTON}</span>
                            <HomepageStyle.DesktopOnly>
                                <Icon>
                                    <Image
                                        src={require('./assets/icon/arrow_right.svg')}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                        }}
                                        alt={'arrow'}
                                    />
                                </Icon>
                            </HomepageStyle.DesktopOnly>
                        </ButtonInner>
                    </StartButton>
                </Link>
                <Link
                    href={
                        locale === 'ja'
                            ? PathsForExternal.IntroductionDocsForJA
                            : PathsForExternal.IntroductionDocsForKO
                    }
                    target="_blank"
                    passHref
                >
                    <DocsButton>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: t.home.HEADER_INTRODUCTION_DOCS,
                            }}
                        />
                    </DocsButton>
                </Link>
                <Strengths>
                    <Strength>
                        <Icon>
                            <Image
                                src={require('./assets/icon/header_ strength_1.svg')}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                }}
                                alt={'최고의 인식률'}
                            />
                        </Icon>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: t.home.HEADER_HIGHEST_RECOGNITION,
                            }}
                        />
                    </Strength>
                    <Strength>
                        <Icon>
                            <Image
                                src={require('./assets/icon/header_ strength_2.svg')}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                }}
                                alt={'최고의 안정성'}
                            />
                        </Icon>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: t.home.HEADER_HIGHEST_STABILITY,
                            }}
                        />
                    </Strength>
                </Strengths>
            </ButtonGroup>
            <SubCatchphrase dangerouslySetInnerHTML={{ __html: t.home.SUB_CATCHPHRASE }} />
            <Demo locale={locale} />
            {locale === 'ko' && <CaseStudyList locale={locale} />}
        </Container>
    );
};

const riseSlow = keyframes`
    0% { opacity: 0; transform: translateY(16px); }
    50% { opacity: 0.7; transform: translateY(10px);}
    100% { opacity: 1; transform: translateY(0); }
`;

const riseFast = keyframes`
    0% { opacity: 0; transform: translateY(16px); }
    50% { opacity: 0; transform: translateY(16px); }
    100% { opacity: 1; transform: translateY(0); }
`;

const fadeIn = keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
`;

const Container = styled.section`
    width: 100%;
    max-width: 1280px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 0 26px 0;
    ${HomepageMedia.forMobile(`
        padding: 62px 0 12px 0;
        text-align: center;
    `)}
`;

const Contents = styled.article`
    width: 100%;
    max-width: 1100px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 24px;
    padding: 0 32px 80px 32px;
    ${HomepageMedia.forMobile(`
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 40px;
        padding: 0 16px 40px 16px;
    `)}
    div:nth-of-type(2) {
        width: 363px;
        height: 244px;
        animation: ${fadeIn} 1s ease-in-out;
        ${HomepageMedia.forMobile(`
            width: 244px;
            height: 164px;
        `)}
    }
`;

const Catchphrase = styled.h1`
    line-height: 120%;
    color: #222;

    font-size: 4rem;
    font-weight: 800;
    animation: ${riseSlow} 0.4s ease-in-out;
    ${HomepageMedia.forMobile(`
        font-size: 1.5rem;
    `)}
    strong {
        background: linear-gradient(270deg, #ff4bf8 2.25%, #4f76ff 102.13%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        animation: ${riseSlow} 0.8s ease-in-out;
    }
`;

const Tags = styled.p`
    color: #a2abc3;

    font-size: 1.25rem;
    font-weight: 400;
    margin-top: 24px;
    word-break: keep-all;
    animation: ${riseFast} 0.8s ease-in-out;
    ${HomepageMedia.forMobile(`
        font-size: 0.875rem; 
        max-width: 166px;
        margin-top: 20px;
    `)}

    span {
        white-space: nowrap;
        margin-right: 4px;
    }
`;

const ButtonGroup = styled.div`
    width: 100%;
    max-width: 1100px;
    padding: 0 32px 0 32px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 26px;
    white-space: nowrap;
    animation: ${riseFast} 1.2s ease-in-out;
    ${HomepageMedia.forMobile(`
        flex-direction: column;
        align-items: center;
        gap: 12px;
    `)}
`;

const StartButton = styled.button`
    position: relative;
    padding: 22px 0;
    border-radius: 14px;
    background: #001f71;
    box-shadow: 0px 14px 32px 0px rgba(0, 31, 113, 0.15);
    width: 234px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;

    color: #fff;

    font-size: 1.25rem;
    font-weight: 600;
    ${HomepageMedia.forMobile(`
        justify-content: center;
        font-size: 0.875rem;
        padding: 16px 0;
        border-radius: 4px;
    `)}
    ${HomepageMedia.forDesktop(`
        &:hover {
            box-shadow: 0px 7px 16px 0px rgba(0, 31, 113, 0.3);
            &,
            &::before,
            &::after {
                background-color: #2e67fe;
            }
            & > div {
                transform: translateX(-8px);
            }
        }
        &::before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 32px;
            background: #001f71;
            z-index: 2;
        }
        &::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            width: 32px;
            background: #001f71;
            z-index: 2;
        }
    `)}
`;

const ButtonInner = styled.div`
    white-space: nowrap;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    ${HomepageMedia.forDesktop(`
        min-width: calc(100% - 28px);
        transition: transform 0.2s ease-in-out;
        transform: translateX(32px);
    `)}
`;

const DocsButton = styled.button`
    padding: 27px 32px;

    border-radius: 14px;
    border: 1px solid #e6ecff;
    background: #fff;

    color: #001f71;
    text-align: center;

    font-size: 1.25rem;
    font-weight: 600;
    ${HomepageMedia.forMobile(`
        width: 234px;
        font-size: 0.875rem; 
        padding: 16px 0;
        border-radius: 4px;
    `)}
`;

const Strengths = styled.div`
    display: flex;
    flex-wrap: nowrap;
    gap: 36px;
    ${HomepageMedia.forMobile(`
        width: 234px;
        gap: 12px;
        justify-content: center;
        margin-top: 6px;
    `)}
`;

const Strength = styled.div`
    display: flex;
    align-items: center;
    gap: 17px;

    color: #a2abc3;

    font-size: 1.125rem;
    font-weight: 600;
    ${HomepageMedia.forMobile(`
        font-size: 0.75rem; 
        font-weight: 400;
        gap: 4px;
    `)}
`;

const SubCatchphrase = styled.h2`
    position: relative;
    width: 100%;
    color: #222;
    text-align: center;

    font-size: 2rem;
    font-weight: 600;
    margin-top: 80px;
    line-height: 60px;
    transition: transform 0.2s ease-in-out;
    background: transparent;
    z-index: 2;
    animation: ${riseFast} 1.4s ease-in-out;
    ${HomepageMedia.forMobile(`
        font-size: 1rem;
        font-weight: 600;
        line-height: 24px;
        margin-top: 40px;
    `)};
    span.home-highlight {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            bottom: 50%;
            left: 50%;
            right: 50%;
            transform: translate(-44%, -50%);
            width: 84px;
            height: 58px;
            background-size: contain;
            z-index: -1;
            background: url('/callabo/highlight_circle.svg') center no-repeat;
            ${HomepageMedia.forMobile(`
                display: none;
            `)}
        }
    }
`;

const Icon = styled.div`
    width: 40px;
    height: 40px;
    ${HomepageMedia.forMobile(`
        width: 24px;
        height: 24px;
    `)}
`;
