import { HomepageMedia } from '../design/HomapageMedia';
import { HomepageStyle } from '../design/HomepageStyle';
import { PathsForExternal } from '@/modules/Paths';
import styled from '@emotion/styled';
import { Locales, useTranslate } from 'callabo-locale/src/hooks/useTranslate';
import Image from 'next/image';
import Link from 'next/link';

export const AppSupport = ({ locale }: { locale: Locales }): JSX.Element => {
    const { t } = useTranslate({ locale });
    return (
        <Container>
            <HomepageStyle.SectionTitle
                dangerouslySetInnerHTML={{ __html: t.home.APP_SUPPORT_TITLE }}
            />
            <Description dangerouslySetInnerHTML={{ __html: t.home.APP_SUPPORT_DESC }} />
            <ButtonGroup>
                <Link href={PathsForExternal.GooglePlayStore} target="_blank">
                    <button>
                        <Logo>
                            <Image
                                src={require('../assets/icon/google_play_store.svg')}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                }}
                                alt="google play store"
                            />
                        </Logo>
                        <span>Google Play</span>
                    </button>
                </Link>
                <Link href={PathsForExternal.AppleAppStore} target="_blank">
                    <button>
                        <Logo>
                            <Image
                                src={require('../assets/icon/apple_app_store.svg')}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                }}
                                alt="apple app store"
                            />
                        </Logo>
                        <span>App Store</span>
                    </button>
                </Link>
            </ButtonGroup>
        </Container>
    );
};

const Container = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    padding: 160px 32px 114px 32px;
    ${HomepageMedia.forMobile(`
        gap: 16px;
        padding: 60px 16px 60px 16px;
    `)}
`;

const Description = styled.p`
    color: #222;
    text-align: center;

    font-size: 1.5rem;
    font-weight: 400;
    word-break: keep-all;
    ${HomepageMedia.forMobile(`
        font-size: 0.875rem;
        max-width: 270px;
    `)}
`;

const ButtonGroup = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    ${HomepageMedia.forMobile(`
        gap: 8px;
        flex-direction: column;
        margin-top: 16px;
    `)}

    button {
        display: flex;
        padding: 20px 32px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 15px;
        border: 1px solid var(--line-ededed, #ededed);

        color: #333;
        text-align: center;

        font-size: 1.25rem;
        font-weight: 500;

        ${HomepageMedia.forMobile(` 
            min-width: 200px;
            font-size: 0.875rem;
            border-radius: 4px;
            padding: 16px 32px;
        `)}
    }
`;

const Logo = styled.div`
    width: 40px;
    height: 40px;
    ${HomepageMedia.forMobile(`
        width: 24px;
        height: 24px;
    `)}
`;
