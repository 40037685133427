import {
    CARD_TEXT_DETAIL,
    DIALOG_CARD_TEXT,
    DIALOG_TITLE,
    PDF_DOWNLOAD_BUTTON,
    PDF_FILE_NAME,
    PDF_SCRIPT,
} from '../assets/script/CaseStudyPDF';
import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';
import { useTranslate } from 'callabo-locale/src/hooks/useTranslate';
import { PageProps } from 'libs/callabo-state/types/PageProps';
import { GlobalToastMessageContext } from 'libs/callabo-ui/contexts/GlobalToastMessageContext';
import { fetchAndDownloadFile } from 'libs/rtzr-commons/DownloadUtils';
import Image from 'next/image';
import { Fragment, useContext, useState } from 'react';

interface Props {
    targetCase: number;
}

const src =
    'https://returnzero-public-image.s3.ap-northeast-2.amazonaws.com/callabo/callabo-homepage-main/%E1%84%8F%E1%85%A9%E1%86%AF%E1%84%85%E1%85%A1%E1%84%87%E1%85%A9_CaseStudy(%E1%84%8E%E1%85%AC%E1%84%8E%E1%85%AC%E1%84%8C%E1%85%A9%E1%86%BC).pdf';

/** @description 한국어일 때만 노출 */
export function CaseStudyCard({ targetCase, locale }: Props & PageProps): JSX.Element {
    const { toast } = useContext(GlobalToastMessageContext);
    const { t } = useTranslate({ locale });
    const [isOpen, setIsOpen] = useState(false);

    const onOpen = () => {
        setIsOpen(true);
    };

    const onClose = () => {
        setIsOpen(false);
    };

    const downloadPDF = async () => {
        try {
            await fetchAndDownloadFile(src, PDF_FILE_NAME);
            toast(t.common.SAVE_SUCCESS);
        } catch (e) {
            toast(t.common.ERROR);
        }
    };

    return (
        <>
            <button
                onClick={onOpen}
                className="
                        bg-blue-pale border border-solid border-blue rounded-3xl p-6 xl:p-8 flex flex-col gap-2.5 text-black-light select-none"
            >
                <p
                    dangerouslySetInnerHTML={{ __html: PDF_SCRIPT[targetCase].title }}
                    className="text-base xl:text-xl font-semibold *:text-primary"
                />
                <p
                    dangerouslySetInnerHTML={{
                        __html: PDF_SCRIPT[targetCase].contents[0].card.replaceAll(
                            '<br/><br/>',
                            '<br/>'
                        ),
                    }}
                    className="text-sm xl:text-base"
                />
                <div className="flex items-center gap-[2px] mt-auto">
                    <span className="text-sm text-[#A2ABC3]">{CARD_TEXT_DETAIL}</span>
                    <Image
                        src={require('../assets/icon/arrow_detail.svg')}
                        width={16}
                        height={16}
                        alt={'detail'}
                    />
                </div>
            </button>
            <Dialog
                open={isOpen}
                onClose={onClose}
                className="fixed inset-0 z-[999] flex w-screen items-center justify-center bg-black/30 p-4 transition duration-300 ease-out data-[closed]:opacity-0 "
                transition
                unmount={false}
            >
                <DialogBackdrop className="fixed inset-0 bg-black/30" />
                <DialogPanel className="fixed top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] bg-white pt-5 md:pt-6 w-[480px] md:w-[1100px] max-w-[calc(100%-32px)] rounded-[20px] md:rounded-lg h-[820px] max-h-[calc(100%-40px)] flex flex-col gap-[2px]">
                    <button onClick={onClose} className="self-end mr-5">
                        <Image
                            src={require('../assets/icon/close.svg')}
                            width={24}
                            height={24}
                            alt="close"
                        />
                    </button>
                    {/* header */}
                    <p className="text-xs md:text-lg text-gray-smoke text-center">{DIALOG_TITLE}</p>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: PDF_SCRIPT[targetCase].title,
                        }}
                        className="text-base md:text-[32px] font-semibold text-center mt-1.5 md:mt-5 px-5"
                    />
                    <div className="overflow-scroll md:my-10">
                        {[...Array(4)].map((_, idx) => {
                            return (
                                <Fragment key={`case-section-${idx}`}>
                                    <div className="mt-5 flex flex-col md:flex-row md:items-center gap-5 md:gap-12 px-5 md:px-10 text-black-light">
                                        <div className="flex flex-col justify-between gap-2 md:h-[410px] w-full md:min-w-[290px] md:max-w-[290px] md:max-h-[410px] bg-primary rounded-lg md:rounded-[20px] p-4 md:pt-6 md:pb-8 md:px-6">
                                            <Image
                                                src={require('../assets/icon/quote.svg')}
                                                alt="quote"
                                                className="w-2 h-5 md:w-3.5 md:h-8 select-none pointer-events-none"
                                            />
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: PDF_SCRIPT[targetCase].contents[idx]
                                                        .card,
                                                }}
                                                className="text-white text-sm md:text-base"
                                            />
                                            <p className="text-xs md:text-sm text-white opacity-50 select-none">
                                                {DIALOG_CARD_TEXT}
                                            </p>
                                        </div>
                                        <div className="md:mt-7 md:h-[410px] pb-2 md:pb-0">
                                            <span className="text-xs md:text-base font-semibold pb-2 md:pb-4 border-b border-solid border-black-light">
                                                {PDF_SCRIPT[targetCase].contents[idx].subTitle}
                                            </span>
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: PDF_SCRIPT[targetCase].contents[idx]
                                                        .description,
                                                }}
                                                className="text-sm md:text-base whitespace-pre-line mt-5 md:mt-10"
                                            />
                                        </div>
                                    </div>
                                </Fragment>
                            );
                        })}
                    </div>
                    <div className="w-full flex justify-end gap-3 md:gap-6 px-5 py-2.5 md:p-5 text-base font-semibold whitespace-nowrap border-t-[1px] border-solid border-gray-ivory select-none">
                        <button
                            onClick={onClose}
                            className="bg-[#EAEAEA] text-black-light py-4 md:py-5 px-7 rounded-lg"
                        >
                            닫기
                        </button>
                        <button
                            onClick={downloadPDF}
                            className="bg-navy text-white py-3.5 px-7 rounded-lg flex gap-2 w-full md:w-auto max-w-[240px] items-center justify-center"
                        >
                            <Image
                                src={require('../assets/icon/pdf.svg')}
                                width={28}
                                height={28}
                                alt="pdf"
                            />
                            <span>{PDF_DOWNLOAD_BUTTON}</span>
                        </button>
                    </div>
                </DialogPanel>
            </Dialog>
        </>
    );
}
