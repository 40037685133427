import { useTranslate } from 'callabo-locale/src/hooks/useTranslate';
import clsx from 'clsx';
import { PageProps } from 'libs/callabo-state/types/PageProps';
import { useCallback, useEffect, useRef, useState } from 'react';

export const UsageDemo = ({ locale }: PageProps): JSX.Element => {
  const { t } = useTranslate();
  const [selectedItem, setSelectedItem] = useState(0);
  const targetRef0 = useRef<HTMLDivElement>(null);
  const targetRef1 = useRef<HTMLDivElement>(null);
  const targetRef2 = useRef<HTMLDivElement>(null);
  const targetRef3 = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  const createObserver = useCallback(
    (target: HTMLDivElement, itemIdx: number, intersectionRatio: number) => {
      const options = {
        root: null,
        threshold: intersectionRatio,
      };
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio >= intersectionRatio) {
            target?.scrollIntoView();
            setSelectedItem(itemIdx);
          }
        });
      }, options);
      return observer;
    },
    []
  );

  useEffect(() => {
    const observer0 = createObserver(targetRef0.current, 0, 1);
    const observer1 = createObserver(targetRef1.current, 1, 0.6);
    const observer2 = createObserver(targetRef2.current, 2, 0.6);
    const observer3 = createObserver(targetRef3.current, 3, 0.6);

    if (targetRef0.current) {
      observer0.observe(targetRef0.current);
    }
    if (targetRef1.current) {
      observer1.observe(targetRef1.current);
    }
    if (targetRef2.current) {
      observer2.observe(targetRef2.current);
    }
    if (targetRef3.current) {
      observer3.observe(targetRef3.current);
    }
    if (videoRef.current) {
      videoRef.current.disableRemotePlayback = true;
    }
    return () => {
      if (targetRef0.current) {
        observer0.unobserve(targetRef0.current);
      }
      if (targetRef1.current) {
        observer1.unobserve(targetRef1.current);
      }
      if (targetRef2.current) {
        observer2.unobserve(targetRef2.current);
      }
      if (targetRef3.current) {
        observer3.unobserve(targetRef3.current);
      }
    };
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
    }
  }, [selectedItem]);

  return (
    <>
      <div className="flex items-center justify-center w-full h-screen sticky pt-24 px-3 xl:pt-36 xl:px-8 top-0 overflow-hidden -translate-y-5 xl:translate-y-0">
        <section className="flex max-h-full flex-wrap xl:flex-nowrap gap-8 xl:gap-24 justify-center xl:justify-start">
          <ul className="flex flex-col gap-1 xl:gap-6 max-w-[400px] xl:max-w-fit px-6 xl:pt-4 xl:px-0">
            <li
              dangerouslySetInnerHTML={{
                __html: t.home.DEMO_MEETING_RETROSPECTIVE,
              }}
              onClick={() => {
                targetRef0.current.scrollIntoView(true);
                setSelectedItem(0);
              }}
              className={clsx(
                'w-full flex justify-center items-center box-border cursor-pointer py-3 px-5 xl:py-6 xl:px-8 text-xs xl:text-xl rounded xl:rounded-lg font-semibold whitespace-nowrap border border-solid border-blue',
                selectedItem === 0
                  ? 'bg-primary text-white'
                  : 'bg-white text-black-light'
              )}
            />
            <li
              dangerouslySetInnerHTML={{ __html: t.home.DEMO_MEETING_REPLAY }}
              onClick={() => {
                targetRef1.current.scrollIntoView(true);
                setSelectedItem(1);
              }}
              className={clsx(
                'w-full flex justify-center items-center box-border cursor-pointer py-3 px-5 xl:py-6 xl:px-8 text-xs xl:text-xl rounded xl:rounded-lg font-semibold whitespace-nowrap border border-solid border-blue',
                selectedItem === 1
                  ? 'bg-primary text-white'
                  : 'bg-white text-black-light'
              )}
            />
            <li
              dangerouslySetInnerHTML={{
                __html: t.home.DEMO_RECORD_ON_AND_OFF,
              }}
              onClick={() => {
                targetRef2.current.scrollIntoView(true);
                setSelectedItem(2);
              }}
              className={clsx(
                'w-full flex justify-center items-center box-border cursor-pointer py-3 px-5 xl:py-6 xl:px-8 text-xs xl:text-xl rounded xl:rounded-lg font-semibold whitespace-nowrap border border-solid border-blue',
                selectedItem === 2
                  ? 'bg-primary text-white'
                  : 'bg-white text-black-light'
              )}
            />
            <li
              dangerouslySetInnerHTML={{ __html: t.home.DEMO_MEETING_SHARE }}
              onClick={() => {
                targetRef3.current.scrollIntoView(true);
                setSelectedItem(3);
              }}
              className={clsx(
                'w-full flex justify-center items-center box-border cursor-pointer py-3 px-5 xl:py-6 xl:px-8 text-xs xl:text-xl rounded xl:rounded-lg font-semibold whitespace-nowrap border border-solid border-blue',
                selectedItem === 3
                  ? 'bg-primary text-white'
                  : 'bg-white text-black-light'
              )}
            />
          </ul>
          <div className="h-full border-8 xl:border-[20px] border-solid border-blue-pale bg-blue-pale overflow-hidden rounded-[28px] xl:rounded-[56px]">
            {(SRC_LIST[locale] ?? SRC_LIST['ko'])?.map((src, index) => {
              return (
                <video
                  key={`usageVideo-${locale}-${index}`}
                  preload="metadata"
                  loop
                  muted
                  autoPlay
                  playsInline
                  disableRemotePlayback
                  style={{
                    display: selectedItem === index ? 'block' : 'none',
                  }}
                  ref={selectedItem === index ? videoRef : null}
                  className="object-cover transition-opacity duration-[0.2s] ease-in-out max-h-[calc(50vh-100px)] xl:max-h-[calc(75vh-130px)]"
                >
                  <source src={src[0]} type="video/mp4" />
                  <source src={src[1]} type="video/webm" />
                </video>
              );
            })}
          </div>
        </section>
      </div>
      <div ref={targetRef0} style={{ height: '60vh', width: '100%' }} />
      <div ref={targetRef1} style={{ height: '100vh', width: '100%' }} />
      <div ref={targetRef2} style={{ height: '100vh', width: '100%' }} />
      <div ref={targetRef3} style={{ height: '100vh', width: '100%' }} />
      <div style={{ height: '40vh', width: '100%' }} />
    </>
  );
};

const SRC_LIST = {
  ko: [
    [
      'https://returnzero-public-image.s3.ap-northeast-2.amazonaws.com/callabo/callabo-homepage-usage/homepage-usage-demo-01-ko.mp4',
      'https://returnzero-public-image.s3.ap-northeast-2.amazonaws.com/callabo/callabo-homepage-usage/homepage-usage-demo-01-ko.webm',
    ],
    [
      'https://returnzero-public-image.s3.ap-northeast-2.amazonaws.com/callabo/callabo-homepage-usage/homepage-usage-demo-02-ko.mp4',
      'https://returnzero-public-image.s3.ap-northeast-2.amazonaws.com/callabo/callabo-homepage-usage/homepage-usage-demo-02-ko.webm',
    ],
    [
      'https://returnzero-public-image.s3.ap-northeast-2.amazonaws.com/callabo/callabo-homepage-usage/homepage-usage-demo-03-ko.mp4',
      'https://returnzero-public-image.s3.ap-northeast-2.amazonaws.com/callabo/callabo-homepage-usage/homepage-usage-demo-03-ko.webm',
    ],
    [
      'https://returnzero-public-image.s3.ap-northeast-2.amazonaws.com/callabo/callabo-homepage-usage/homepage-usage-demo-04-ko.mp4',
      'https://returnzero-public-image.s3.ap-northeast-2.amazonaws.com/callabo/callabo-homepage-usage/homepage-usage-demo-04-ko.webm',
    ],
  ],
  ja: [
    [
      'https://returnzero-public-image.s3.ap-northeast-2.amazonaws.com/callabo/callabo-homepage-usage/homepage-usage-demo-01-ja.mp4',
      'https://returnzero-public-image.s3.ap-northeast-2.amazonaws.com/callabo/callabo-homepage-usage/homepage-usage-demo-01-ja.webm',
    ],
    [
      'https://returnzero-public-image.s3.ap-northeast-2.amazonaws.com/callabo/callabo-homepage-usage/homepage-usage-demo-02-ja.mp4',
      'https://returnzero-public-image.s3.ap-northeast-2.amazonaws.com/callabo/callabo-homepage-usage/homepage-usage-demo-02-ja.webm',
    ],
    [
      'https://returnzero-public-image.s3.ap-northeast-2.amazonaws.com/callabo/callabo-homepage-usage/homepage-usage-demo-03-ja.mp4',
      'https://returnzero-public-image.s3.ap-northeast-2.amazonaws.com/callabo/callabo-homepage-usage/homepage-usage-demo-03-ja.webm',
    ],
    [
      'https://returnzero-public-image.s3.ap-northeast-2.amazonaws.com/callabo/callabo-homepage-usage/homepage-usage-demo-04-ja.mp4',
      'https://returnzero-public-image.s3.ap-northeast-2.amazonaws.com/callabo/callabo-homepage-usage/homepage-usage-demo-04-ja.webm',
    ],
  ],
  en: [
    [
      'https://returnzero-public-image.s3.ap-northeast-2.amazonaws.com/callabo/callabo-homepage-usage/homepage-usage-demo-01-en.mp4',
      'https://returnzero-public-image.s3.ap-northeast-2.amazonaws.com/callabo/callabo-homepage-usage/homepage-usage-demo-01-en.webm',
    ],
    [
      'https://returnzero-public-image.s3.ap-northeast-2.amazonaws.com/callabo/callabo-homepage-usage/homepage-usage-demo-02-en.mp4',
      'https://returnzero-public-image.s3.ap-northeast-2.amazonaws.com/callabo/callabo-homepage-usage/homepage-usage-demo-02-en.webm',
    ],
    [
      'https://returnzero-public-image.s3.ap-northeast-2.amazonaws.com/callabo/callabo-homepage-usage/homepage-usage-demo-03-en.mp4',
      'https://returnzero-public-image.s3.ap-northeast-2.amazonaws.com/callabo/callabo-homepage-usage/homepage-usage-demo-03-en.webm',
    ],
    [
      'https://returnzero-public-image.s3.ap-northeast-2.amazonaws.com/callabo/callabo-homepage-usage/homepage-usage-demo-04-en.mp4',
      'https://returnzero-public-image.s3.ap-northeast-2.amazonaws.com/callabo/callabo-homepage-usage/homepage-usage-demo-04-en.webm',
    ],
  ],
};
