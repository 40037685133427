import { HomepageMedia } from '../design/HomapageMedia';
import { HomepageStyle } from '../design/HomepageStyle';
import { PathName } from '@/modules/PathName';
import { Paths } from '@/modules/Paths';
import styled from '@emotion/styled';
import { Locales, useTranslate } from 'callabo-locale/src/hooks/useTranslate';
import Image from 'next/image';
import Link from 'next/link';

export const UseCase = ({ locale }: { locale: Locales }): JSX.Element => {
    const { t } = useTranslate({ locale });
    return (
        <HomepageStyle.Card order={5} height={1130}>
            <HomepageStyle.CardTitle dangerouslySetInnerHTML={{ __html: t.home.USE_CASE_TITLE }} />
            <Link href={new PathName(Paths.Usage).setLocale(locale).toString()}>
                <Detail dangerouslySetInnerHTML={{ __html: t.common.SHOW_DETAIL }} />
            </Link>
            <Content>
                <Item>
                    <Illust>
                        <Image
                            src={require('../assets/icon/use_case_1.svg')}
                            style={{ width: '100%', height: '100%' }}
                            alt="세일즈 담당"
                        />
                    </Illust>
                    <Name dangerouslySetInnerHTML={{ __html: t.home.USE_CASE_TYPE_1_NAME }} />
                    <Description
                        dangerouslySetInnerHTML={{ __html: t.home.USE_CASE_TYPE_1_DESC }}
                    />
                </Item>
                <Item>
                    <Illust>
                        <Image
                            src={require('../assets/icon/use_case_2.svg')}
                            style={{ width: '100%', height: '100%' }}
                            alt="인사 담당"
                        />
                    </Illust>
                    <Name dangerouslySetInnerHTML={{ __html: t.home.USE_CASE_TYPE_2_NAME }} />
                    <Description
                        dangerouslySetInnerHTML={{ __html: t.home.USE_CASE_TYPE_2_DESC }}
                    />
                </Item>
                <Item>
                    <Illust>
                        <Image
                            src={require('../assets/icon/use_case_3.svg')}
                            style={{ width: '100%', height: '100%' }}
                            alt="프리랜서"
                        />
                    </Illust>
                    <Name dangerouslySetInnerHTML={{ __html: t.home.USE_CASE_TYPE_3_NAME }} />
                    <Description
                        dangerouslySetInnerHTML={{ __html: t.home.USE_CASE_TYPE_3_DESC }}
                    />
                </Item>
                <Item>
                    <Illust>
                        <Image
                            src={require('../assets/icon/use_case_4.svg')}
                            style={{ width: '100%', height: '100%' }}
                            alt="팀 리더"
                        />
                    </Illust>
                    <Name dangerouslySetInnerHTML={{ __html: t.home.USE_CASE_TYPE_4_NAME }} />
                    <Description
                        dangerouslySetInnerHTML={{ __html: t.home.USE_CASE_TYPE_4_DESC }}
                    />
                </Item>
                <Item>
                    <Illust>
                        <Image
                            src={require('../assets/icon/use_case_5.svg')}
                            style={{ width: '100%', height: '100%' }}
                            alt="제품개발팀"
                        />
                    </Illust>
                    <Name dangerouslySetInnerHTML={{ __html: t.home.USE_CASE_TYPE_5_NAME }} />
                    <Description
                        dangerouslySetInnerHTML={{ __html: t.home.USE_CASE_TYPE_5_DESC }}
                    />
                </Item>
                <Item>
                    <Illust>
                        <Image
                            src={require('../assets/icon/use_case_6.svg')}
                            style={{ width: '100%', height: '100%' }}
                            alt="고객상담부서"
                        />
                    </Illust>
                    <Name dangerouslySetInnerHTML={{ __html: t.home.USE_CASE_TYPE_6_NAME }} />
                    <Description
                        dangerouslySetInnerHTML={{ __html: t.home.USE_CASE_TYPE_6_DESC }}
                    />
                </Item>
            </Content>
        </HomepageStyle.Card>
    );
};

const Detail = styled.span`
    color: #2e67fe;
    text-align: center;

    font-size: 1.25rem;
    font-weight: 400;
    margin-top: 32px;
    cursor: pointer;
    ${HomepageMedia.forMobile(`
        font-size: 0.875rem;
        margin-top: 16px;
    `)}
`;

const Content = styled.ul`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    list-style: none;
    gap: 70px 0;
    margin-top: 64px;
    padding: 0 32px;
    ${HomepageMedia.forMobile(`
        gap: 60px 4px;
        margin-top: 34px;
        padding: 0 12px;
    `)}
`;

const Item = styled.li`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 14px;
    ${HomepageMedia.forMobile(`
        gap: 4px;
        white-space: wrap;
    `)}
`;

const Name = styled.p`
    color: #222;
    text-align: center;

    font-size: 1.25rem;
    font-weight: 600;
    margin-top: 5px;
    ${HomepageMedia.forMobile(`
        font-size: 1rem;
        margin-top: 12px;
    `)}
`;

const Description = styled.p`
    color: #222;
    text-align: center;

    font-size: 1.25rem;
    font-weight: 400;
    ${HomepageMedia.forMobile(`
        font-size: 0.875rem;
    `)}
`;

const Illust = styled.div`
    width: 120px;
    height: 120px;
    ${HomepageMedia.forMobile(`
        width: 68px;
        height: 68px;
    `)}
`;
