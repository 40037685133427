import { HomepageMedia } from '../design/HomapageMedia';
import { HomepageStyle } from '../design/HomepageStyle';
import styled from '@emotion/styled';
import { Locales, getValidLocale, useTranslate } from 'callabo-locale/src/hooks/useTranslate';
import Image from 'next/image';

export const Analysis = ({ locale }: { locale: Locales }): JSX.Element => {
    const { t } = useTranslate({ locale });
    return (
        <HomepageStyle.Card order={3} height={944}>
            <Container>
                <HomepageStyle.CardTitle
                    dangerouslySetInnerHTML={{ __html: t.home.HOW_TO_ANALYSIS_TITLE }}
                />
                <HomepageStyle.CardDescription
                    dangerouslySetInnerHTML={{ __html: t.home.HOW_TO_ANALYSIS_DESC }}
                    style={{ maxWidth: 510 }}
                />
                <Content>
                    <Image
                        src={require(`../assets/image/${getValidLocale(locale)}/analysis.png`)}
                        width={506}
                        height={506}
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                        alt="발화분석"
                    />
                </Content>
            </Container>
        </HomepageStyle.Card>
    );
};

const Container = styled.div`
    ${HomepageMedia.forMobile(`
        padding-left: 20px;
        padding-right: 20px;
    `)}
`;

const Content = styled.div`
    margin-top: 98px;
    margin-bottom: 15px;
    ${HomepageMedia.forMobile(`
        margin-top: 40px;
    `)}
`;
