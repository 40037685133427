export const DIALOG_TITLE = '콜라보 Case Study';
export const CARD_TEXT_DETAIL = '고객사와 인터뷰 자세히 보기';
export const DIALOG_CARD_TEXT = '실제 고객사와 인터뷰 했던 피드백 인용';
export const PDF_DOWNLOAD_BUTTON = 'PDF 다운로드';
export const PDF_FILE_NAME = '콜라보 Case Study.pdf';
export const PDF_SCRIPT = [
    {
        title: '엔터프라이즈 <span>내부회의 자산화</span>',
        contents: [
            {
                subTitle: '고객사 고민',
                card: '하루에 회의 최소 3~5개씩 하는데 꼼꼼하게 정리해서 공유하는데만도 하루가 걸려요.<br/><br/>이걸 전체 구성원으로 생각하면 엄청난 리소스가 들어가고 있거든요. DX 전략팀에서는 이걸 해결해야 되는 상황이에요.',
                description:
                    'OOO는 한국의 대표적인 엔터테인먼트 및 미디어 기업으로, 방송, 영화, 음악, 공연 등 다양한 콘텐츠를제작하고 유통하는 종합 엔터테인먼트 기업입니다.<br/><br/>OOO 는 내부 팀 회의의 경우 인당 하루 평균 3~5 개로, 많은 시간을 회의에 할애하고 있었습니다. 회의 수가 증가함에 따라 이러한 회의에서 공유되어야 하는 중요한 정보를 공유하는 것이 어려워졌고, 지속적으로 회의록을 작성하는 것 자체가 업무에 큰 부담이 되어 결국 수기로 작성해야 하는 회의록이 남지 않는 경우가 많아졌습니다.OOO는 한국의 대표적인 엔터테인먼트 및 미디어 기업으로, 방송, 영화, 음악, 공연 등 다양한 콘텐츠를제작하고 유통하는 종합 엔터테인먼트 기업입니다.<br/><br/>OOO 는 내부 팀 회의의 경우 인당 하루 평균 3~5 개로, 많은 시간을 회의에 할애하고 있었습니다. 회의 수가 증가함에 따라 이러한 회의에서 공유되어야 하는 중요한 정보를 공유하는 것이 어려워졌고, 지속적으로 회의록을 작성하는 것 자체가 업무에 큰 부담이 되어 결국 수기로 작성해야 하는 회의록이 남지 않는 경우가 많아졌습니다.',
            },
            {
                subTitle: '해결책 탐색',
                card: '기업에서 도입해서 사용할 수 있는, 클로바노트 대안이 되는 솔루션을 찾다가 콜라보를 알게되었습니다.',
                description:
                    'DX 전략팀에서는 구성원들이 클로바노트를 사용하여 회의 내용을 녹음하고 음성인식 결과를 회의록 작성하는데 사용한다는 것을 알게되었습니다.<br/><br/>그러나 클로바노트는 B2C 제품으로, 워크스페이스 기능이 없어서 검토 대상에서 빠르게 제외되었습니다. 클로바노트 대안으로 AI 회의록 솔루션을 찾던 중 콜라보를 알게되었습니다.',
            },
            {
                subTitle: '도입결정 사유',
                card: '파일럿에 참여한 모든 팀들의 요약의 퀄리티에 대한 만족도가 굉장히 높았어요.<br/><br/>사용하기 시작한 지 얼마 지나지 않아 자연스레 도입하자라는 분위기가 생겼습니다.',
                description:
                    'OOO는 파일럿 형태로 1달 가량의 테스트 기간을 거쳐 도입을 결정했습니다.<br/><br/>콜라보를 사용해보고 가장 큰 임팩트를 느낀 부분은 요약의 퀄리티였습니다. LLM의 등장으로 인해, 이를 활용한 모든 솔루션이 요약을 제공하지만 검토해 본 모든 제품 중 요약의 퀄리티가 가장 뛰어났습니다. 단순 목차 형식으로 긴 회의 정보 전체를 나열하는 것이 아니라, 중요한 내용을 3문단 형식으로 제공하고 액션 아이템까지 추출해주는 점을 구성원들이 가장 만족스러워했습니다.<br/><br/>오프라인 대면 회의, 온라인( 줌, 구글밋, 팀즈) 회의 또는 통화까지 어떤 형태로 회의를 해도 다 쉽게 저장해서 데이터화 할 수 있는 콜라보의 아카이빙 인프라도 잘 구축되어 있는 점 또한 엔터프라이즈로서 중요 고려 사항이었습니다. 슬랙을 메인으로 사용하고 있는데, 콜라보 <> 슬랙 연동을 제공해서 내부 구성원들이 기존에 익숙한 툴에서 업무가 이루어질 수 있도록 하는 점 또한 도입을 결정하게 된 사유 중 하나입니다.',
            },
            {
                subTitle: '도입 후 결과',
                card: '콜라보 도입 후 제 시간에 퇴근할 수 있게 되었어요.<br/><br/>온라인 컨퍼런스 참석해도 공유내용 정리에 하루이틀 걸리는데 콜라보 링크 전달로 1분 소요 했습니다.<br/><br/>칼퇴 충성 ! 감사합니다 !',
                description:
                    '<b>구성원들의 ‘회의’에 대한 인식 자체가 긍정적으로 변화되었습니다.</b><br/>콜라보를 통해 회의가 항상 데이터로 남아있기 때문에 회의 참석자가 모두 모여야 회의를 진행하는 기존의 방식에서 벗어나 일정이 맞는 사람들만 우선 모여서 빠르게 논의할 수 있게 되었습니다. 회의에 참석하지 못한 사람은 콜라보에서 내용을 확인하고 의견을 남기는 방식으로 일하는 문화 자체가 바뀌었습니다.<br/><br/><b>업무 진행 상황에 대한 가시성이 0 -> 100% 로 증가했습니다.</b><br/>회의에 불참하면 구두로 문의해야만 하는 기존의 방식에서는 타 부서 회의 내용은 더더욱 알기 힘들었습니다. 3문단 요약, 주요 논의사항 및 액션아이템이 회의가 끝나자마자 슬랙 채널로 공유되니 원활하게 혈액 순환이 잘 되는 것처럼 업무 협의가 활발하게 이루어지고 있습니다.<br/><br/><b>휘발되던 회의가 데이터로 남고, 회사의 자산으로 저장되기 시작했습니다.</b><br/>CS 상담 내역이 남아 있는 것이 당연한 것처럼, 회의도 자동으로 저장되기 시작하니 비로소 데이터로서 활용될 수 있다는 걸 알게되었습니다. 기록으로 남고 저장되기 전에는 알지 못했던 가장 큰 혜택 중 하나입니다.',
            },
        ],
    },
    {
        title: '<span>세일즈포스 데이터로깅</span> 자동화',
        contents: [
            {
                subTitle: '고객사 고민',
                card: '안 그래도 세일즈포스가 비싸잖아요.<br/><br/>비싸긴 한데 결국 기업이 커지면 세일즈포스로 갈 수밖에 없더라고요.<br/><br/>그런데 저희가 그 세일즈포스 기능을 제대로 활용하고 있지는 않아서 먼저 할 수 있는게 뭘까 고민했어요.',
                description:
                    '스펙터(specter.kr)는 한국의 HR 테크 플랫폼으로, 구직자의 이름과 전화번호만으로 10초 안에 신원 조회와 평판 확인을 할 수 있는 빠른 채용 검증 서비스를 제공하는 기업입니다. 이를 통해 기업들은 빠르게 채용 결정을 내릴 수 있어 고용 실패와 비용을 줄일 수 있습니다.<br/><br/>스펙터의 세일즈 팀에서는 세일즈 현황 관리를 위해 세일즈포스를 도입해 사용했으나 단순히 세일즈 파이프라인 관리 도구로만 사용되고 있었습니다.<br/>주로 Opportunity 기능만 사용했고, 대부분의 세일즈 활동 기록은 남지 않거나 노션의 미팅 노트로 관리되었습니다. 이로 인해 세일즈포스에 기록된 숫자와 실제 재무 숫자 간의 불일치가 발생했고, 원인을 파악할 수 없었습니다.<br/><br/>특히 Enterprise 기업이 주요 Target이었던 만큼, 전화 통화 비중이 높았으나 그 동안 통화는 기록되지 못해, 세일즈 히스토리를 파악할 수 없는 문제가 있었습니다.',
            },
            {
                subTitle: '해결책 탐색',
                card: '클로바노트 쓰다가 용량에 대한 제한이 너무 커서 그게 불편하더라고요.<br/><br/>그러다 보니 모든 미팅을 다 녹취할 수는 없고 회의가 길어지거나 딜 사이즈가 큰 미팅에만 제한적으로 사용하고 있었어요.<br/><br/>그래서 클로바노트를 활용하면서 약간의 한계점이 보여가지고 다른 곳은 없을까라고 해서요.',
                description:
                    '스펙터에서는 개개인 단위로 미팅 노트 작성 시 내용을 누락하지 않기 위해 클로바노트를 사용하고 있었습니다.<br/><br/>그러나 클로바노트는 기업용 제품으로 출시되지 않아, 하루에도 2~3개 이상의 미팅을 진행하는 AE들이 모든 미팅을 저장하고 활용할 수 있는 수준으로 충분한 레코딩 시간을 제공하지 못하고 있었습니다.<br/><br/>그러다 보니 자연스럽게 미팅 내용이 누락되는 문제가 지속적으로 발생하고 있었습니다.<br/><br/>또한 클로바노트의 AI 미팅 요약을 통해 미팅 내용을 기록하려고 시도했으나, AI 요약의 품질이 미팅 노트를 기록할 수 있는 수준만큼 높지 않아 다른 제품을 찾게 되었습니다.',
            },
            {
                subTitle: '도입결정 사유',
                card: '저희는 대기업 고객을 많이 만나니까 현장 나가서 미팅하는 거하고 전화통화가 엄청 많은데, 그걸 하나하나 수기로 입력하려니까 막 아주 미칠 것 같아가지고요.<br/><br/>저도 그렇고 저희 이사님이 특히 통화가 많으신데 이제는 모든 통화까지 다 손쉽게 기록할 수 있어서요.',
                description:
                    '스펙터는 1달간의 PoC 진행 후 콜라보를 정식으로 세일즈 팀에 도입했습니다.<br/><br/>콜라보를 도입하게 된 가장 큰 이유는 고객과의 모든 음성 커뮤니케이션을 저장하고 세일즈포스에 손쉽게 기록할 수 있다는 점이었습니다.<br/><br/>특히 대기업을 상대하는 스펙터의 특성상 대부분의 미팅이 오프라인으로 진행되고, 미팅 이후에도 팔로업을 위한 전화 통화가 매우 빈번했는데, 콜라보를 도입한 이후부터는 미팅이나 통화가 많아도 손쉽게 기록할 수 있어, 고객과의 커뮤니케이션에 있어 누락되는 업무를 거의 없앨 수 있었습니다.<br/><br/>그리고 세일즈포스에 기록하는 일이 쉬워졌다 보니, 세일즈포스에서 분석할 수 있는 데이터가 많아져, 단순히 지표만을 확인하던 분석에서 벗어나 지표를 기반으로 세일즈 히스토리 기반의 정성적인 분석이 가능해졌습니다.',
            },
            {
                subTitle: '도입 후 결과',
                card: '저한테 제일 필요한 솔루션이에요.<br/><br/>그리고 저희 팀에서 온라인&오프라인 미팅뿐만 아니라 전화까지 모두 저장된다는 거에 이미 와우 포인트를 많이 느꼈고 Value Buying이 돼서 이제는 업무에 없어서는 안될 툴이라고 생각합니다.',
                description:
                    '콜라보 도입 이후, 스펙터는 이제 세일즈포스에 대해 더 긍정적인 시각을 가지게 되었습니다.<br/>콜라보를 통해 세일즈포스에 고객과의 히스토리를 쉽게 남길 수 있게 되었고, 이제는 히스토리 확인이 필요할 때마다 세일즈포스에서 바로 고객과 나눈 대화의 디테일을 확인할 수 있게 되었습니다.<br/><br/>또한 그 동안 누락되고 있던 세일즈 히스토리들을 기반으로 한 정성적인 분석을 통해, 세일즈 데이터를 좀더 정밀하게 분석할 수 있게 되었습니다.<br/><br/>그 결과, 세일즈 히스토리를 기반으로 현재 세일즈 현황을 좀더 정밀하게 파악할 수 있게 되었고, 세일즈포스의 데이터를 정밀하게 분석해 회사의 세일즈 결과를 개선할 수 있게 되었습니다.',
            },
        ],
    },
    {
        title: '영업대표 실력 향상을 위한 <span>세일즈코칭</span>',
        contents: [
            {
                subTitle: '고객사 고민',
                card: '작년에 헤드카운트를 공격적으로 늘리고 관리를 잘 못해서 세일즈 퀄리티의 위기를 경험했습니다.<br/><br/>이후 스케일하게 팀을 확장해도 퀄리티가 떨어지지 않을 수 있는 방법이 필요하다고 생각했습니다.',
                description:
                    '채널코퍼레이션은 기업들이 고객과의 소통을 강화하고 효율적인 고객 관리 시스템을 제공하는 B2B SaaS인 채널톡을 만들고 있습니다.<br/><br/>채널톡은 모든 업무에 대해 기록을 남기는 것에 대한 가치를 최우선으로 두고 있고, 영업 인력이 계속해서 성장할 수 있는 문화를 만들기 위해 퀄리티 컨트롤을 매우 중요하게 생각하고 있었습니다. 그래서 클로바노트를 사용하여 고객과의 미팅 내용을 녹음하고, 미팅이 끝난 후 클로바노트로 저장된 미팅 원본을 분석하면서 개선점을 도출하고 있었습니다.<br/>그러나 클로바노트는 B2C 제품으로, 워크스페이스 기능이 없어서 고객 미팅이 회사의 자산이 될 수 없다는 단점과,  화상 미팅이 대부분인 채널톡의 상황 상 고객이 실시간으로 보여주는 리액션을 분석하기 위한 영상 녹화 기능이 없어 불만을 가지고 있었습니다.',
            },
            {
                subTitle: '해결책 탐색',
                card: '클로바노트가 좋은데, 레코딩 시간도 너무 적고 결정적으로 화상미팅에 쓰기가 불편하거든요.<br/><br/>콜라보는 화상미팅도 캘린더만 연동하면 알아서 녹화해주니 편하게 쓸 수 있겠다고 생각했어요.',
                description:
                    '채널코퍼레이션에서는 영업 인력을 성장시키고 일관적인 영업 결과를 지속적으로 만들기 위해, 오디오 녹음뿐만 아니라 화상미팅도 같이 저장할 수 있는 제품을 찾고 있었습니다.<br/><br/>처음에는 외국의 제품들을 탐색했으나, 불편한 사용 경험 및 한국어 품질 저하 등의 이유로 인해 적극적인 제품 도입은 어려운 상황이었습니다. 그렇다고 클로바노트를 계속 사용하자니, 한 달 동안 진행하는 미팅 수 대비 너무나 부족한 레코딩 시간으로 인해 저장하지 못하는 미팅이 많았습니다.<br/><br/>그러던 도중, 클로바노트의 대안으로 온라인&오프라인 미팅을 모두 원본으로 저장할 수 있는 콜라보를 알게되었습니다.',
            },
            {
                subTitle: '도입결정 사유',
                card: '모든 것들을 다 사운드로만 녹음을 하게 되면 퀄리티 컨트롤이 안 되다 보니까 텍스트로 전환하는 게 되게 중요하고요.<br/><br/>또 기록이랑 영상이 같이 남는 게 되게 중요해서 그냥 계속 가야 된다고 생각하고 있습니다.',
                description:
                    '채널톡은 약 1달의 사용 기간을 거쳐 콜라보를 정식으로 도입했습니다.<br/><br/>결정적으로 콜라보를 도입하기로 결심했던 부분은, 화상미팅의 화면 녹화가 전부 다 되어 고객의 표정까지도 자세하게 분석해 개선점을 찾을 수 있다는 점이었습니다.<br/><br/>또한 발화 비율도 같이 추출되어, 영업 미팅에서 내가 너무 말을 많이 한 것은 아닌지, 고객이 집중하지 못했던 부분은 어디인지 등도 파악하고 개선할 수 있다는 점이 도입을 결심하게 된 이유였습니다.',
            },
            {
                subTitle: '도입 후 결과',
                card: '콜라보를 도입하고 리뷰 문화가 되게 좋아지고 피드백 받는 문화가 생기고, 그러다 보니까 자연스럽게 퀄리티가 높아지고 그러다 보니까 영업을 성사시킬 수 있는 것들이 되게 많아졌어요.',
                description:
                    '콜라보를 도입한 이후, 채널톡의 영업 미팅 리뷰 문화는 한층 더 성장했습니다. 이제 채널톡의 영업사원들은 언제든 자신이 진행했던 미팅을 다시 확인하면서 부족한 부분을 찾아 보완하고, 오디오뿐만 아니라 영상 원본을 기반으로 자유롭게 피드백을 받아 성장할 수 있게 되었습니다.<br/><br/><b>영업 미팅을 공유하고 서로 피드백하는 문화 활성화</b><br/>영업 미팅 리뷰가 활성화되면서, 이제는 미팅 리뷰에 익숙하지 않던 팀원들도 미팅 리뷰가 팀 전체 활동 중 제일 1 순위가 되고, 이걸 안 하면 성장할 수 없다는 분위기가 형성되어 구성원들이 자발적으로 리뷰를 요청하는 문화가 형성되었습니다.<br/><br/><b>리뷰 프로세스 도입 후 30% 이상 매출 향상</b><br/>영업 미팅 리뷰를 통해 부족한 점을 개선하고 다음 영업 미팅에 피드백을 반영해 좀더 많은 영업 건을 성사시켜, 매출을 증진시킬 수 있게 되었습니다.',
            },
        ],
    },
    {
        title: 'VoC 기반 <span>제품개발</span> 문화',
        contents: [
            {
                subTitle: '고객사 고민',
                card: '제품을 만들어가면서 기능 개발 우선순위에 대해 세일즈 관점과 개발 관점이 서로 다르잖아요.<br/><br/>이 두 관점에서 의견이 계속 충돌했었고, 의사결정을 위한 커뮤니케이션 비용이 너무 큰 상황이었습니다.',
                description:
                    '스플랩 팀은 ‘사람과 사람을 연결하는 프로젝트’를 미션으로, 이벤트 커뮤니티를 빌딩할 수 있는 Umoh, 간편하게 일정을 스케줄링할 수 있는 ‘센드타임’이라는 B2B SaaS를 만들고 있습니다.<br/><br/>스플랩 팀은 제품 출시 후 하루에 3~4개의 고객 미팅을 진행했고, 주로 주말에 미팅을 몰아서 정리하고 VoC를 확인했습니다. 그런데 미팅을 정리하다 보니 고객 VoC 를 해석하고 제품에 반영하기 위해 들어가는 영업팀과 제품팀의 커뮤니케이션 비용이 너무 크다는 걸 깨달았습니다.<br/><br/>같은 고객에 대해서도 영업팀과 제품팀의 VoC에 대한 의견이 다 달라, 제품 우선순위에 대해 의견이 계속 충돌했었고, 의사결정을 위한 커뮤니케이션이 길어지는 문제를 가지고 있었습니다.',
            },
            {
                subTitle: '해결책 탐색',
                card: '고객과 진행한 미팅 원본을 바탕으로 개발팀/영업팀이 커뮤니케이션 해야 한다는 걸 깨달았어요.<br/><br/>그 지점을 해결해 줄 수 있는 툴을 찾다가 콜라보를 알게되었습니다.',
                description:
                    '고객사에서는 제품 개선을 위한 커뮤니케이션에 들어가는 비용을 줄이기 위해 여러가지 방법을 찾고 있었습니다. 처음에는 고객이 요구한 사항을 미팅 노트 형태로 정리해 협업 툴에 공유하고, 정리된 노트를 기반으로 커뮤니케이션했으나 여전히 의견이 일치되는 데 많은 시간이 걸렸습니다. 결국 이 방법을 유지할 수 없다고 생각하게 되었습니다.<br/><br/>클로바노트도 시도해봤지만 B2C 제품이다 보니, 녹음된 내용을 팀에 공유하는 것이 불편하고, 개인 단위로 원본이 저장되다 보니 과거의 미팅을 찾아보는 것도 너무 불편해, 결국 손으로 고객 VoC를 공유하는 방법으로 돌아가는 경우가 많았습니다.<br/><br/>그러던 중 고객과의 미팅을 원본 그대로 저장하고 협업툴 연동을 통해 팀에 공유할 수 있는 콜라보라는 제품을 알게되었습니다.',
            },
            {
                subTitle: '도입결정 사유',
                card: '고객미팅 원본을 개발팀도 확인할 수 있게 되고, 고객이 왜 그런 기능을 요청했는지에 대한 배경이나 뉘앙스까지 담겨 있는 점이 바로 대표인 제가 원하던 부분이었어요.',
                description:
                    'PoC를 통해 콜라보를 정식으로 도입했고, 가장 만족했던 기능은 미팅을 자동으로 요약하고, 슬랙 채널에 요약을 전송해주는 기능입니다.<br/>스플랩에서는 협업툴인 Slack을 통해 팀 내 커뮤니케이션을 진행하고 있었습니다. 그리고 콜라보에서는 Slack 연동 기능을 지원하기 때문에, 사람이 수동으로 미팅 내용을 공유하지 않아도 실시간으로 어디와 미팅을 했는지, 미팅에서 어떤 내용을 논의했는지 바로 파악할 수 있어서 고객이 요청한 부분 중 지원이 필요한 부분을 빠르게 확인할 수 있는 게 가장 핵심적인 도입 사유였습니다.<br/><br/>그리고 콜라보에 Guest 권한으로 영업팀 이외의 인원도 고객 미팅을 볼 수 있게 초대가 가능해, 제품팀이 영업팀에 요청하지 않아도 자유롭게 고객 미팅을 확인하고 VoC를 찾아볼 수 있다는 점도 도입을 결정한 이유였습니다.',
            },
            {
                subTitle: '도입 후 결과',
                card: '팀 전체적으로 고객에 대한 이해도가 높아졌어요.<br/><br/>저희 팀은 고객을 직접 만나지 않는 개발자도 고객에 대해 최대한 많이 알아야 한다고 생각하고, “왜 고객이 이렇게 얘기한 거에요?” 라고 묻는 건 고객을 모르고 있다는 증거라고 생각해요.',
                description:
                    '<b>제품 개발 속도 50% 향상</b><br/>이제 스플랩에서는 제품 개발 우선순위를 정하는 데 들어가는 커뮤니케이션 비용이 3배 이상 향상되었습니다. 콜라보가 없을 때에는 미팅노트와 기억에만 의존해 커뮤니케이션을 했는데, 이제는 의견이 충돌하면 바로 콜라보에 저장된 미팅을 확인하고 고객의 실제 VoC를 기반으로 빠르게 의사 결정이 가능해졌습니다.<br/><br/><b>개발팀 사기 진작 및 재직기간 증가</b><br/>고객을 만나보고 대화를 나누다 보면, 내가 만들고 싶은 기능이 고객이 원하는 기능이 아닐 수도 있다는 걸 깨닫는 경우가 많습니다. 반대로 특정 기능에 대해 확신이 없을 때 고객의 VoC 를 통해 기능 개발에 대해 확신을 가지는 경우도 많아졌습니다. 이제는 자연스럽게 고객에게 집요할 정도로 관심을 가지는 문화가 만들어졌고, 지금은 콜라보로 저장된 고객 미팅을 통해 긍정적인 제품 피드백도 확인하면서 팀 사기 진작에 큰 도움을 받았습니다.<br/><br/>이제는 개발팀 모두 콜라보에 저장된 고객 미팅을 보고, 고객이 무엇을 원하는지, 어떤 기능을 개발해야 하는지에 대해 적극적으로 의견을 개진하는 One team 문화가 되었습니다.',
            },
        ],
    },
    {
        title: '신규 입사자 <span>온보딩</span>',
        contents: [
            {
                subTitle: '고객사 고민',
                card: '첫 세일즈 인력을 채용해야 하는데, 아무런 준비가 되어있지 않더라고요.<br/><br/>어떻게 교육해서 빠르게 회사에 적응시켜 성과를 내게 할 지 고민이었습니다.',
                description:
                    'OOO는 B2B 기업의 마케터들을 대상으로, 마케터들이 링크드인 등의 B2B SNS 에 퍼블리싱할 컨텐츠 Demand Creation을 돕는 B2B SaaS 를 만드는 기업입니다.<br/><br/>OOO는 제품이 성장함에 따라 영업 팀의 구축이 필요해졌고, 새로운 영업 인력을 채용하는 과정에서 영업 인력이 적응에 실패해 성과를 내지 못하는 것과, 내부적인 교육 프로세스가 없어 영업 인력이 회사에 만족하지 못해 빠르게 퇴사하는 상황을 우려했습니다.<br/><br/>OOO에서는 신규 영업 인력 채용 시, 영업 인력이 빠르게 회사에 적응할 수 있게 도와주는 교육 자료가 없다는 것을 깨달았습니다. 교육 자료가 없다면 담당자가 구두로 회사의 제품 및 영업 방식을 교육해야 하는데, 이 방식은 시간이 오래 걸리고 비효율적임을 깨달았습니다.',
            },
            {
                subTitle: '해결책 탐색',
                card: '대표인 저는 제품에 대해 잘 알고있지만, 새롭게 들어오시는 분들은 그걸 잘 모르잖아요.<br/><br/>고객과의 영업 미팅을 회사 자산으로 만들면 신규 입사자의 교육 자료로 활용할 수 있겠다고 생각했습니다.',
                description:
                    'OOO에서는 신규 영업인력을 빠르게 교육시키기 위해서 반드시 회사가 교육 자료를 준비해야 한다는 점을 깨달았고, 그 중에서도 고객사와의 영업 미팅을 자산화할 수 있는 여러 제품을 탐색했습니다.<br/><br/>하지만 해외의 여러 제품들(Gong.io, Grain.com, Otter.ai) 및 국내 제품인 클로바노트 등을 탐색했으나, 한국어 품질 및 영업 현장에 맞지 않는 사용성 때문에 온보딩에 사용할 제품을 선택하지 못했습니다.<br/><br/>그러던 도중 고객과의 모든 커뮤니케이션을 데이터로 만들 수 있는 콜라보 제품을 알게되었습니다.',
            },
            {
                subTitle: '도입결정 사유',
                card: '저는 향후 잠재적인 채용 실패로 인한 금전적 손실, 초기 기업으로서 인재 영입 후 실패에 대한 두려움 때문에 콜라보를 도입했습니다.',
                description:
                    'OOO는 2 주의 PoC  기간을 거쳐 콜라보를 도입했습니다.<br/>콜라보에서 가장 만족했던 부분은, 어떠한 미팅 방식도 모두 콜라보로 저장 및 자산화가 가능하다는 점이었습니다. 주로 사용하는 툴인 MS Teams 이외에도, 고객사가 Google Meet이나 Zoom 혹은 오프라인 미팅을 요청한 경우에도 세일즈 미팅을 자산으로 만들 수 있다는 점이 가장 큰 강점이었습니다.<br/><br/>또한 다양한 영업 상황을 교육 자료로 만들어 신규 입사자에게 제공할 수 있다는 점을 PoC를 통해 깨달았습니다. 고객의 구매 거절 사유 등이 회사의 자산으로 저장되어, 신규 입사자가 영업 현장에 직접 나가지 않아도 고객을 이해할 수 있다는 점이 도입을 결정한 이유였습니다.',
            },
            {
                subTitle: '도입 후 결과',
                card: '보통 신규 영업인력을 채용하면 최소 6개월에서 1년 정도는 적응기간이 필요하다고 하잖아요.<br/><br/>콜라보로 저장된 미팅을 교육자료로 활용하면서, 저희는 2~3개월이면 실전에 투입할 수 있을 정도로 교육이 가능해졌어요.',
                description:
                    '콜라보 도입 이후, OOO은 이제 자신있게 세일즈 인력을 채용할 수 있게 되었습니다.<br/><br/><b>신규 입사자의 고객 이해도 증진</b><br/>OOO의 제품 가치 및 사명을 기반으로, 신규 입사자가 고객과의 미팅한 내용만 계속 혼자 돌려보게만 해도 눈에 보이고 이해되는 것들이 있어서 고객의 상황을 하나하나 다 설명해줄 필요 없이, 고객에게 어떤 가치를 제안해야 하는지와 구매 거절 사유를 어떻게 극복할 수 있는지를 저장된 미팅을 기반으로 실감나게 교육할 수 있게 되었습니다.<br/><br/><b>최소한의 시간을 사용한 효율적인 신규 입사자 교육</b><br/>그리고 고객을 이해하기 위해 직접 현장에 신규 입사자를 데리고 나가는 시간도 줄어들어, 더 적은 시간을 쓰고도 효율적으로 신규 입사자를 교육할 수 있게 되었습니다.',
            },
        ],
    },
];
