import { Debounce, DebounceOptions } from 'libs/rtzr-commons/Debounce';
import { useCallback, useEffect, useRef } from 'react';

export function useDebounce(delay = 700): {
    debounce: (fn: () => void, options?: DebounceOptions) => void;
} {
    const ref = useRef<Debounce>();

    const debounce = useCallback(
        (fn: () => void, options?: DebounceOptions) => {
            ref.current && ref.current.exec(fn, options);
        },
        [ref]
    );

    useEffect(() => {
        ref.current = new Debounce(delay);
    }, []);

    return { debounce };
}
