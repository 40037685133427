export default function debounce(fn: () => void, ms = 300): () => void {
    let timeoutId: ReturnType<typeof setTimeout>;
    return function (this: any, ...args: any[]) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => fn.apply(this, args), ms);
    };
}

export function debounceWithFunction(ms = 300): (fn: () => void) => void {
    let timeoutId: ReturnType<typeof setTimeout>;
    return function (fn: () => void) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => fn(), ms);
    };
}

export interface DebounceOptions {
    leading?: boolean;
}

export class Debounce {
    private timeoutId: ReturnType<typeof setTimeout> | undefined;

    constructor(private readonly ms: number = 300) {}

    public exec(fn: () => void, options?: DebounceOptions): void {
        if (options && options.leading) {
            this.leading(fn);
        } else {
            clearTimeout(this.timeoutId);
            delete this.timeoutId;
            this.timeoutId = setTimeout(() => fn(), this.ms);
        }
    }

    private leading(fn: () => void) {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
            delete this.timeoutId;
            this.timeoutId = setTimeout(() => {
                fn();
                delete this.timeoutId;
            }, this.ms);
        } else {
            fn();
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            this.timeoutId = setTimeout(() => {}, this.ms);
        }
    }
}
