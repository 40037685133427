import { HomepageMedia } from '../design/HomapageMedia';
import { HomepageStyle } from '../design/HomepageStyle';
import styled from '@emotion/styled';
import { Locales, useTranslate } from 'callabo-locale/src/hooks/useTranslate';
import Link from 'next/link';

export const EmphasisPayment = ({ locale }: { locale: Locales }): JSX.Element => {
    const { t } = useTranslate({ locale });
    return (
        <Container>
            <HomepageStyle.SectionTitle
                dangerouslySetInnerHTML={{ __html: t.home.EMPHASIS_ON_PAYMENT_TITLE }}
            />
            <Description dangerouslySetInnerHTML={{ __html: t.home.EMPHASIS_ON_PAYMENT_DESC }} />
            <Link href={'/pricing'} passHref>
                <PricingLinkButton
                    dangerouslySetInnerHTML={{ __html: t.home.EMPHASIS_ON_PAYMENT_PRICING }}
                />
            </Link>
        </Container>
    );
};

const Container = styled.section`
    padding: 120px 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    ${HomepageMedia.forMobile(`
        padding: 60px;
        gap: 16px;
    `)}
`;

const Description = styled.p`
    color: #222;
    text-align: center;

    font-size: 1.5rem;
    font-weight: 400;

    ${HomepageMedia.forMobile(`
        font-size: 0.875rem;
    `)}
`;

const PricingLinkButton = styled.button`
    color: #001f71;
    text-align: center;

    font-size: 1.25rem;
    font-weight: 600;

    display: flex;
    padding: 27px 32px;
    justify-content: center;
    align-items: center;

    border-radius: 14px;
    border: 1px solid #e6ecff;
    background: #fff;
    transition: all 0.2s ease-in-out;

    &:hover {
        background: #2e67fe;
        color: #fff;
        box-shadow: 0px 7px 16px 0px rgba(0, 31, 113, 0.3);
        border: 1px solid transparent;
    }

    ${HomepageMedia.forMobile(`
        font-size: 0.875rem;
        padding: 16px 32px;
        border-radius: 4px;
        margin-top: 16px;
    `)}
`;
