import { HomepageMedia } from '../design/HomapageMedia';
import styled from '@emotion/styled';
import { Locales, useTranslate } from 'callabo-locale/src/hooks/useTranslate';
import Image from 'next/image';

export const Feature = ({ locale }: { locale: Locales }): JSX.Element => {
    const { t } = useTranslate({ locale });
    return (
        <Card>
            <Content>
                <Item>
                    <Image
                        src={require('../assets/icon/feature_1.svg')}
                        width={40}
                        height={40}
                        layout="fixed"
                        className="flex-shrink-0"
                        alt="회의록 무제한"
                    />

                    <Keyword
                        dangerouslySetInnerHTML={{ __html: t.home.FEATURE_KEYWORD_MEETING_RECORDS }}
                    />
                </Item>
                <Item>
                    <Image
                        src={require('../assets/icon/feature_2.svg')}
                        width={40}
                        height={40}
                        layout="fixed"
                        className="flex-shrink-0"
                        alt="발화비율"
                    />

                    <Keyword
                        dangerouslySetInnerHTML={{
                            __html: t.home.FEATURE_KEYWORD_SPEAKER_ANALYSIS,
                        }}
                    />
                </Item>
                <Item>
                    <Image
                        src={require('../assets/icon/feature_3.svg')}
                        width={40}
                        height={40}
                        layout="fixed"
                        className="flex-shrink-0"
                        alt="플랫폼"
                    />

                    <Keyword
                        dangerouslySetInnerHTML={{ __html: t.home.FEATURE_KEYWORD_PLATFORM }}
                    />
                </Item>
                <Item>
                    <Image
                        src={require('../assets/icon/feature_4.svg')}
                        width={40}
                        height={40}
                        layout="fixed"
                        className="flex-shrink-0"
                        alt="오프라인"
                    />

                    <Keyword
                        dangerouslySetInnerHTML={{ __html: t.home.FEATURE_KEYWORD_OFFLINE_MEETING }}
                    />
                </Item>
                <Item>
                    <Image
                        src={require('../assets/icon/feature_5.svg')}
                        width={40}
                        height={40}
                        layout="fixed"
                        className="flex-shrink-0"
                        alt="다국어"
                    />

                    <Keyword
                        dangerouslySetInnerHTML={{ __html: t.home.FEATURE_KEYWORD_MULTILINGUAL }}
                    />
                </Item>
                <Item>
                    <Image
                        src={require('../assets/icon/feature_6.svg')}
                        width={40}
                        height={40}
                        layout="fixed"
                        className="flex-shrink-0"
                        alt="권한관리"
                    />

                    <Keyword
                        dangerouslySetInnerHTML={{ __html: t.home.FEATURE_KEYWORD_AUTH_MANAGE }}
                    />
                </Item>
                <Item>
                    <Image
                        src={require('../assets/icon/feature_7.svg')}
                        width={40}
                        height={40}
                        layout="fixed"
                        className="flex-shrink-0"
                        alt="협업툴"
                    />

                    <Keyword
                        dangerouslySetInnerHTML={{
                            __html: t.home.FEATURE_KEYWORD_INTEGRATION_TOOLS,
                        }}
                    />
                </Item>
                <Item>
                    <Image
                        src={require('../assets/icon/feature_8.svg')}
                        width={40}
                        height={40}
                        layout="fixed"
                        className="flex-shrink-0"
                        alt="공유"
                    />

                    <Keyword
                        dangerouslySetInnerHTML={{ __html: t.home.FEATURE_KEYWORD_MEETING_SHARE }}
                    />
                </Item>
            </Content>
        </Card>
    );
};

const Card = styled.article`
    box-sizing: border-box;
    border: 1px solid #e6ecff;
    background: #f6f9ff;
    width: 100%;
    ${HomepageMedia.forDesktop(`
        max-width: 1280px;
        padding: 80px 0;
        border-radius: 56px;
    `)}
    ${HomepageMedia.forMobile(`
        width: calc(100% - 24px);
        max-width: 400px;
        padding: 40px 16px;
        border-radius: 28px;
        margin-top: 12px;
    `)}
`;

const Content = styled.ul`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 28px;
    list-style: none;
    ${HomepageMedia.forMobile(`
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
    `)}
`;

const Item = styled.li`
    flex-basis: 124px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    ${HomepageMedia.forMobile(`
        flex-basis: 76px;
        gap: 16px;
    `)}
`;

const Keyword = styled.p`
    color: #222;

    font-size: 1.25rem;
    font-weight: 500;
    text-align: center;
    ${HomepageMedia.forMobile(`
        font-size: 0.875rem;
        font-weight: 400;
    `)}
`;
